

.header-auth-container{

	

	.container-header{
		min-height: 75px;
		background-color: #151515;

		@include tablet{
			min-height: 60px;
		}
	}

	.logo-header-auth{
		max-width: 170px;

		@include phone{
			max-width: 120px;
		}
	}


	.banner-header-container{
		position: absolute;
	    z-index: 1;	   
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	    text-align: center;
	    width: 728px;
	    height: 90px;
	}

	.container-right-options{
		margin-right: 100px;


		.text-hello-user{
			font-size: 12px;
			padding: 0 5px 0 10px;
			color: #9e9e9e;
			span{
				font-style: italic;
			}
		}

		.container-personal-account-icon{
			background-color: #dcdcdc;
		    color: black;
		    border-radius: 18px;
		    padding: 7px;
		}

		@include desktop{
			margin-right: 10px;
		}

		.icon-personal-account{
			svg{
				font-size: 25px;
			}
			span{
				display: block;
				font-size: 12px;	
				margin: -5px;			
			}

		}
	
	}

	.menu-option{
		font-size: 14px;
		color: $C_TEXT_LIGHT;
		margin-right: 25px;
		&:hover{
			color: $C_SECONDARY;
		}

		@include desktop{
			display: none;
		}
	}

	.title-menu-option{
		font-size: 10px;
		text-transform: uppercase;
		
	}

	.logo-header{
		font-family: $brandFontSerif;
		font-size: 30px;
		margin-left: 50px;
		a{
			color: $C_TEXT_LIGHT;
		}

		@include desktop{
			margin-left: 0px;
		}

		@include tablet{
			margin-left: 0;
			text-align: center;
		}
	}



	button{
		color: $C_TEXT_LIGHT;
		.down-icon{
			svg{
				font-size: 12px;
				padding-left: 5px;
			}
		}
		svg{
			font-size: 20px;
		}

		&:hover{
			background-color: transparent;
		}
	}

	

}

.container-drawer{
	.drawer-logo{
		font-family: $brandFontSerif;
		margin-left: 15px;
	}

	.option-mobile-icon{

		&:hover{
			svg{
				color: $C_SECONDARY;
			}
			.option-mobile-text{
				color: $C_SECONDARY;
			}
		}
	}


	.option-mobile-text{
		padding: 0 16px;

		
		

	}
}

.text-option-menu{
	a{
		color: $C_TEXT_LIGHT;
		&:hover{
			color: $C_SECONDARY;
		}
	}
	
	svg{
		font-size: 16px;
	}
}

.logo-header-no-auth{

	max-width: 200px;
	margin-top: 5px;

	@include phone{
		max-width: 160px;
	}
}

.btn-call-menu{
	font-size: 14px;
	letter-spacing: 1px;
    border-radius: 3px;
    padding: 7px 13px;
    border: 0;
    color: #fff !important;

    &:hover{
    	background-color: $C_SECONDARY_DARK !important;
    }
}

.no-logged-call-me{
	position: absolute;
	top: 20px;
	right: 20px;

	@include phone{
		right: 7px;
	}
}
