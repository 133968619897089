
/*
VARIABLES DE COLOR
*/

$C_MAIN: #fff;
$C_MAIN_LIGHT: #f5f5f5;
$C_MAIN_DARK: #c2c2c2;

$C_SECONDARY: #05bdac;
$C_SECONDARY_LIGHT: #03c7b5;
$C_SECONDARY_DARK: #009688;

/*
$C_SECONDARY: #009688;
$C_SECONDARY_LIGHT: #03c7b5;
$C_SECONDARY_DARK: #00675b;
*/

$C_TEXT: #292929;
$C_TEXT_SEMILIGHT: #a7a7a7;
$C_TEXT_LIGHT: #dcdcdc;

$ERROR_COLOR: #ed4c3e;

/*
@font-face {font-family: 'Bodoni'; src: url('/fonts/bodoni.woff') format('woff');}
*/



$brandFont: 'Poppins', sans-serif;
$brandFontSerif: 'Bodoni Moda', serif;

/*
VARIABLES DE COLOR
*/



body{
	margin: 0;
	font-family: $brandFont;
  font-weight: 400;
	background: #fff;
	color: $C_TEXT;
}

h1{
	font-family: $brandFontSerif;
  font-weight: 400;
	margin: 0;
}

a{
	text-decoration: none;
	cursor: pointer;
}

a:hover {
    text-decoration: none;
}
a:focus {
    text-decoration: none;
}

button{
	cursor: pointer;
  font-family: $brandFont;
}

// Media Queries
/*
$iphoneretina-width: 320px;
$phone-width: 480px; 
$tablet-width: 768px; 
$desktop-width: 992px; 
$large-width: 1200px; */

$iphoneretina-width: 320px;
$phone-width: 600px; /* xs */
$tablet-width: 960px; /* sm */
$desktop-width: 1280px; /* md - breakpoint MENU */
$large-width: 1920px; /* lg */ 



    /*==========  NON Mobile First Method  ==========*/

@mixin large {
    /* Large Devices, Wide Screens */
    @media only screen and (max-width : #{$large-width}) {
     @content;
    }
}

@mixin desktop {
    /* Medium Devices, Desktops */
    @media only screen and (max-width : #{$desktop-width}) {
      @content;
    }
}

@mixin tablet {
    /* Small Devices, Tablets */
    @media only screen and (max-width : #{$tablet-width}) {
     @content;
    } 
}

@mixin phone {
    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : #{$phone-width}) {
      @content;
    }
}

@mixin iphoneretina {
    /* Custom, iPhone Retina */ 
    @media only screen and (max-width : #{$iphoneretina-width}) {
     @content;
    }
}

/*
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
*/

.container-block-login, .container-block{
  margin: 0 auto; 
  padding: 0 15px;
  text-align: center;
  max-width: 400px;
}


.btn{

  padding: 7px 14px;
  font-size: 14px;
  outline: none;
  border: 0;
  border-radius: 3px;

  .icon-btn{
    margin-left: 10px;
    margin-right: 10px;
    font-size: 20px;
  }

}

.btn1{
  background-color: $C_SECONDARY;
  color: #fff;

  &:hover{
    background-color: $C_SECONDARY_LIGHT;
  }

}

.btn-lead{
  padding: 10px 20px;
  font-size: 14px;
  outline: none;
  border: 0;
  border-radius: 3px;
  letter-spacing: 1px;
  text-transform: uppercase;
}


.facebook-btn{

  background-color: #3b5998;
  color: #fff;
  width: 80%;
  margin: 3px auto;
  padding: 11px 14px;

  .text-btn{
    margin-bottom: 11px;
      position: relative;
      bottom: 1px;
  }

  &:hover{
    background-color: #4c70ba;
  }

  @include phone{
    width: 100%;
  }

}

.google-btn{

  background-color: #EA4335;
  color: #fff;
  width: 80%;
  margin: 3px auto;
  padding: 11px 14px;
  
  .text-btn{
    margin-bottom: 11px;
      position: relative;
      bottom: 1px;
  }

  &:hover{
    background-color: #ff4e3f;
  }
  
  @mixin tablet{
    width: 90%;
  }

  @include phone{
    width: 100%;
  }

}


input:-internal-autofill-previewed, input:-internal-autofill-selected, 
textarea:-internal-autofill-previewed, textarea:-internal-autofill-selected, 
select:-internal-autofill-previewed, select:-internal-autofill-selected {
   
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
    border-radius: 5px;
}


@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}   
}


input, select, textarea{
    font-size: 16px;
    font-weight: normal;
    color: #d4d4d4;
    background-color: transparent;
    border: 1px solid #e5e5e5;   
    padding: 6px 12px;
    border-radius: 3px;
    display: block;
    outline: none;

    @include placeholder {
      font-style:italic;
      color: #afafaf;          
  }

  
}

.inputContainer{
  input, select, textarea{
    &:focus{
      box-shadow: 0 0 5px rgba(2, 161, 146, 0.5) !important;    
      border: 1px solid rgba(2, 161, 146, 1) !important;
    }
  }
}







