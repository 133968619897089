


.template-contact-page{

	margin-top: 130px;

	@include tablet{
		margin-top: 80px;
	}

	.container-block-contact{
		background-color: $C_MAIN_LIGHT;
		text-align: center;
		margin-bottom: 50px;
		.icon-title-section{
			font-size: 110px;
			margin: 0;
			color: $C_MAIN_DARK;
		}

		.title-text{
			padding-top: 30px;
			font-family: $brandFontSerif;
			font-size: 25px;
			font-weight: 700;
		}

		.title-section{
			text-align: center;
			font-family: $brandFontSerif;
			font-size: 25px;
			margin: 0px;
			color: $C_MAIN_DARK;
			font-weight: 700;
		}

		.text-comments{
			color: $C_TEXT_SEMILIGHT;
			margin: 0;
		}

		.inputContainer{
			margin: 15px 5px 0 5px;
			text-align: left;

			@include phone{
				margin: 15px 10px 0 10px;
			}
		}

		.inputContainerCheckbox{
			margin: 15px 0; 

			.input-label{

				a{
					color: $C_SECONDARY;
				}

			}
		}

		input{

			border: 1px solid #A2A2A2;  
			color: #505050;
			margin: 0;
			/*width: 100%;*/
			width: -webkit-calc(100% - 24px);
	    	width:    -moz-calc(100% - 24px);
	    	width:         calc(100% - 24px);
	    	height: 25px;
		}

		textarea{
			width: -webkit-calc(100% - 24px);
	    	width:    -moz-calc(100% - 24px);
	    	width:         calc(100% - 24px);
	    	height: 150px;
	    	border: 1px solid #A2A2A2;  
			color: #505050;
			background-color: transparent;
		}

		input[type='checkbox']{
		

			border: 1px solid #A2A2A2;  
			color: #505050;
			margin: 0;
			height: 25px;
			width: 25px;
			display: inline-block;		
			position: relative;
			top: 5px;
		}

		.input-label{
			color: $C_TEXT;
			font-size: 14px;
		}

		select{
			border: 1px solid #A2A2A2;  
			color: #505050;
			height: 40px;
			width: 100%;
		}

		form{
			.error-container{
				color: $ERROR_COLOR;
				text-align: left;
				font-size: 12px;
				margin-bottom: 10px;
				margin-top: 0px;
				.icon-error{
					margin-right: 5px;
				}
			}

			.error-alert{
				background-color: #f5efee;
			    padding: 7px;
			    text-align: left;
			    border-left: 3px solid $ERROR_COLOR;
			    color: $ERROR_COLOR;
			}

			.btn-contact{
				margin: 15px;
			}
		}

	}

}

.template-thankyou-page{

	min-height: calc(100vh - 200px);
	margin-top: 100px;

	.container-block-thanyou{
		
		text-align: center;
		/*background-image: url(https://www.motorprivee.com/media/back_treball-01.png);
		background-position: center; 
		background-repeat: no-repeat; 
		background-size: cover;
		*/
		.title-text{
			padding-top: 20px;
			text-transform: uppercase;
			font-size: 90px;
			font-weight: 700;
			margin: 0;

			@include phone{
				font-size: 50px;
			}
		}

	}

	.container-block-cancel-thanyou{
		.title-text{
			padding-top: 20px;
			text-transform: uppercase;
			font-size: 50px;
			font-weight: 700;
			margin: 0;
			text-align: center;
			@include phone{
				font-size: 50px;
			}
		}
	}

	.container-text-cancel-thankyou{
		margin-top: 50px;
		margin-bottom: 30px;
		text-align: center;

		.text-comments{
			text-align: center;
		}	
	}

	.container-text-thankyou{
		margin-bottom: 30px;
		text-align: center;

		.text-comments{
			text-align: center;
		}	

	}

	.image-thanks{

		@include phone{
			max-width: 275px;
		}
		

	}
}

.template-loading-thanks{
	min-height: calc(100vh - 60px);
	margin-top: 230px;
	text-align: center;
}

.template-legal{

	margin-top: 125px;
	min-height: calc(100vh - 200px);

	@include tablet{
		margin-top: 75px;
	}	

	.container-title{

		text-align: center;
		margin-bottom: 30px;

	}

}

.template-faqs{

	margin-top: 125px;
	min-height: calc(100vh - 200px);
	margin-bottom: 40px;
	

	@include tablet{
		margin-top: 75px;
	}	

	.container-title{

		text-align: center;
		margin-bottom: 30px;

	}

	.question-title{
		margin: 0;
		font-weight: 700;
	}

	.question-answer{
		font-size: 14px;
		a{
			color: $C_SECONDARY;
			text-decoration: underline;
		}
	}

}


.fc-widget-small{
	bottom: 85px !important;
}


.template-edit-date{

	margin-top: 130px;

	@include tablet{
		margin-top: 80px;
	}

	.container-block-contact{
		background-color: $C_MAIN_LIGHT;
		text-align: center;
		margin-bottom: 50px;
		

		.title-text{
			padding-top: 30px;
			font-family: $brandFontSerif;
			font-size: 25px;
			font-weight: 700;
		}

		.text-comments{
			color: $C_TEXT_SEMILIGHT;
			margin: 0;
			padding: 30px 0 0 0;
		}

		.text-code{
			font-size: 20px;
			font-weight: 700;
			padding: 0;
			margin-top: 10px;
		}

		.text-explanation{
			color: $C_TEXT_SEMILIGHT;
		}

		.error-explanation{
			color: $C_TEXT_SEMILIGHT;
			padding-bottom: 30px;
		}

		textarea{
			width: -webkit-calc(100% - 24px);
	    	width:    -moz-calc(100% - 24px);
	    	width:         calc(100% - 24px);
	    	height: 150px;
	    	border: 1px solid #A2A2A2;  
			color: #505050;
			background-color: transparent;
		}

		.input-label{
			color: $C_TEXT;
			font-size: 14px;
		}

		
		form{
			.error-container{
				color: $ERROR_COLOR;
				text-align: left;
				font-size: 12px;
				margin-bottom: 10px;
				margin-top: 0px;
				.icon-error{
					margin-right: 5px;
				}
			}

			.error-alert{
				background-color: #f5efee;
			    padding: 7px;
			    text-align: left;
			    border-left: 3px solid $ERROR_COLOR;
			    color: $ERROR_COLOR;
			}

			.btn-contact{
				margin: 15px;
			}
		}
		
	}

}

.template-change-pwd-page-fire{

	.change-fire-box{
		background-color: rgba(0, 0, 0, 0.8);
	    text-align: center;
	    padding: 20px 30px;
	    margin: 40px auto;
	    color: #dcdcdc;
	}

	.title-sub-section{
		color: #dcdcdc;
		border-bottom: 1px solid #dcdcdc;
		font-family: $brandFontSerif;
		text-align: left;
	    padding-bottom: 5px;
	    margin-top: 0;
	    margin-bottom: 30px;
	}

	input{
		
		width: 90%;
    	margin: 0 auto;
    	margin-bottom: 15px;
	}
	.input-label{
		color: #dcdcdc;
	}

	.forget-link{
		margin-top: 20px;
		background-color: $C_SECONDARY;
		color: $C_TEXT_LIGHT;
		padding: 5px 13px;
	    border-radius: 3px;
	    display: inline-block;
	}

}





