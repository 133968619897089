
/* 
START BACKGROUND CHANGE PHOTO 
*/

	

@keyframes cf4FadeInOut {
  0% {
    opacity:1;
  }
  26% {
    opacity:1;
  }
  33% {
    opacity:0;
  }
  92% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

/*
.container-images-bg div:nth-of-type(1) {
  animation-delay: 30s;
}

.container-images-bg div:nth-of-type(2) {
  animation-delay: 20s;
}

.container-images-bg div:nth-of-type(3) {
  animation-delay: 10s;
}

.container-images-bg div:nth-of-type(4) {
  animation-delay: 0;
}
*/

.container-images-bg div:nth-of-type(1) {
  animation-delay: 26s;
}

.container-images-bg div:nth-of-type(2) {
  animation-delay: 13s;
}

.container-images-bg div:nth-of-type(3) {
  animation-delay: 0s;
}





.container-background{
	
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    bottom: auto;
    right: auto;
    z-index: -10;
    
    -webkit-animation-name: cf4FadeInOut;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 39s;
    -moz-animation-name: cf4FadeInOut;
    -moz-animation-timing-function: ease-in-out;
    -moz-animation-iteration-count: infinite;
    -moz-animation-duration: 39s;
    -o-animation-name: cf4FadeInOut;
    -o-animation-timing-function: ease-in-out;
    -o-animation-iteration-count: infinite;
    -o-animation-duration: 39s;
    animation-name: cf4FadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 39s;

}

/* 
END BACKGROUND CHANGE PHOTO 
*/


/*
 START LOGIN PAGE
*/
.template-login-page{

	margin-top: 100px;
	min-height: calc(100vh - 250px);
	

	@include phone{
		margin-top: 30px;
	}

	.container-text-intro{
		padding: 20px;
		background-color: rgba(0,0,0,0.6);
	}

	.text-intro{
		color: $C_MAIN;		
		border-top: 1px solid;
		border-bottom: 1px solid;		
		text-align: center;
		padding: 20px 0;
		letter-spacing: 2px;
		font-size: 18px;
		line-height: 40px;
		
	}

	.login-box{
		background-color: rgba(0,0,0,0.95);
		
		text-align: center;
		padding: 20px 30px;
	}

	.facebook-btn{
		background-color: #3b5998;
		color: #fff;
		width: 80%;
		margin: 3px auto;
		padding: 11px 14px;

		@include phone{
			width: 100%;
		}
		.text-btn{
			margin-bottom: 11px;
		    position: relative;
		    bottom: 1px;
		}
		&:hover{
			background-color: #4c70ba;
		}

	}

	.google-btn{
		background-color: #EA4335;
		color: #fff;
		width: 80%;
		margin: 3px auto;
		padding: 11px 14px;

		@mixin tablet{
			width: 90%;
		}


		@include phone{
			width: 100%;
		}
		
		.text-btn{
			margin-bottom: 11px;
		    position: relative;
		    bottom: 1px;
		}
		&:hover{
			background-color: #ff4e3f;
		}
		
	}

	.separator-with-text{

		color: #fff;
    	height: 40px;
    	line-height: 40px;
    	text-align: center;    
    	width: 100%;

    	&:before, &:after {
    		content: '';
		    display: inline-block;
		    position: relative;
		    height: 1px;
		    width: 50%;
		    background-color: #fff;
		    vertical-align: middle;
    	}

    	&:before{
    		right: 0.5em;
    		margin-left: -50%;
    	}

    	&:after{
    		left: 0.5em;
    		margin-right: -50%;
    	}

	}


	.login-form{

		.inputContainer{
			margin: 5px auto;
			
			input{
				font-size: 16px;
			    font-weight: normal;
			    color: #d4d4d4;
			    background-color: transparent;
			    border: 1px solid #e5e5e5;
			    width: 90%;
			    height: 25px;
			    padding: 6px 12px;
			    border-radius: 3px;
			    display: block;
			    @include placeholder {
				    font-style:italic;
				    color: #afafaf;				   
				}
			}
			

		}

		.btn-signin{
			width: 100%;
			padding: 12px 14px;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.container-link-forget{
			text-align: left;
			margin: 0 0 10px 0;
		}
		.forget-link{
			
			text-decoration: underline;
			color: $C_TEXT_LIGHT;
			font-size: 12px;
		}
	}
	
	.link-login{
		color: $C_TEXT_LIGHT;
		text-decoration: underline;
		font-size: 14px;
		cursor: pointer;
	}

	.signin-form{
		.inputContainer{
			margin: 5px auto;
			
			input{
				font-size: 16px;
			    font-weight: normal;
			    color: #d4d4d4;
			    background-color: transparent;
			    border: 1px solid #e5e5e5;
			    width: 90%;
			    height: 25px;
			    padding: 6px 12px;
			    border-radius: 3px;
			    display: block;
			    @include placeholder {
				    font-style:italic;
				    color: #afafaf;				   
				}
			}

		}

		.btn-signin{
			width: 100%;
			padding: 12px 14px;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		input[type='checkbox']{
		

			border: 1px solid #A2A2A2;  
			color: #505050;
			margin: 0;
			height: 25px;
			width: 25px;
			display: inline-block;		
			position: relative;
			top: 5px;
		}

		.inputContainerCheckbox{
			text-align: left;

			.input-label{
				color: #fff;
				font-size: 13px;
				a{
					color: $C_SECONDARY;
				}

				@include phone{
					font-size: 12px;
				}
			}
		}
		


	}

	form{
		.error-container{
			color: $ERROR_COLOR;
			text-align: left;
			font-size: 12px;
			margin-bottom: 10px;
			margin-top: 2px;
			.icon-error{
				margin-right: 5px;
			}
		}

		.error-alert{
			background-color: #f5efee;
		    padding: 7px;
		    text-align: left;
		    border-left: 3px solid $ERROR_COLOR;
		    color: $ERROR_COLOR;
		}
	}


}

.text-alert-change-pwd{
	text-align: center;
	color: $C_TEXT_SEMILIGHT;
	margin: 0;
}

/*
 END LOGIN PAGE
*/

/*
 START FORGET PWD PAGE
*/

.template-forget-pwd-page{

	margin-top: 100px;
	min-height: calc(100vh - 250px);


	@include phone{
		margin-top: 20px;
	}

	.forget-pwd-box{
		background-color: rgba(0,0,0,0.6);		
		text-align: center;
		padding: 20px 30px;
		margin: 40px auto;
	}

	.title-box{
		margin: 0 0 20px 0;
		text-align: left;
		font-size: 14px;
		color: $C_TEXT_LIGHT;
		font-family: $brandFontSerif;
		padding-bottom: 3px;
		padding-left: 5px;
		border-bottom: 1px solid;
	}

	.explanation-text{
		color: $C_TEXT_LIGHT;
		font-size: 14px;


	}

	.reset-pwd-form{
		.inputContainer{
			margin: 5px auto;
			
			input{
				font-size: 16px;			    
			    color: #d4d4d4;
			    background-color: transparent;
			    border: 1px solid #e5e5e5;
			    width: 90%;
			    margin: 0 auto;
			    height: 25px;
			    padding: 6px 12px;
			    border-radius: 3px;
			    display: block;
			    @include placeholder {
				    font-style:italic;
				    color: #afafaf;				   
				}
			}

		}

		.btn-reset-pwd{
			width: 50%;
			padding: 12px 14px;
			margin-top: 10px;
			margin-bottom: 20px;
		}

	}

	form{
		.error-container{
			color: $ERROR_COLOR;
			text-align: left;
			font-size: 12px;
			margin-bottom: 10px;
			margin-top: 2px;
			.icon-error{
				margin-right: 5px;
			}
		}

		.error-alert{
			background-color: #f5efee;
		    padding: 7px;
		    text-align: left;
		    border-left: 3px solid $ERROR_COLOR;
		    color: $ERROR_COLOR;
		}
	}

	.text-confirmation{
		font-size: 12px;
		background-color: #dafbf8;
		padding: 10px 5px 10px 15px;
		border-left: 3px solid $C_SECONDARY_LIGHT ;
		text-align: left;
		color: $C_SECONDARY;
	}

	.link-login{
		color: $C_TEXT_LIGHT;
		text-decoration: underline;
		font-size: 12px;
		cursor: pointer;
		margin-top: 7px;
    	display: block;
	}

	.link-top{
		margin-top: 25px;
	}

}

/*
 END FORGET PWD PAGE
*/

/*
 START EDIT PROFILE PAGE
*/


.template-personal-data{

	margin-top: 150px !important;
	min-height: calc(100vh - 250px);


	@include phone{
		margin-top: 100px;
	}

	.container-block-personal-data{
		background-color: #fff;
		padding: 15px;		
		border-radius: 5px;
		text-align: center;


	}

	.inputContainer{
		margin: 15px 5px 0 5px;
		text-align: left;		
	}

	.icon-title-section{
		margin: 20px 0 0 0;
    	font-size: 75px;
    	color: #d4d4d4;
	}

	.title-section{
		text-align: center;
		font-family: $brandFontSerif;
		font-size: 25px;
		margin: 0px 0 45px 0;
		color: $C_TEXT;
		font-weight: 700;
	}

	input{

		border: 1px solid #A2A2A2;  
		color: #505050;
		margin: 0;
		/*width: 100%;*/
		width: -webkit-calc(100% - 24px);
    	width:    -moz-calc(100% - 24px);
    	width:         calc(100% - 24px);
    	height: 25px;
    	background-color: #fff;
	}

	input:disabled{
	  border: 1px solid #e0e0e0;
	  color: #7d7d7d;
	  font-style: italic;
	  background-color: #d4d4d4;
	}

	select{
		border: 1px solid #A2A2A2;  
		color: #505050;
		height: 40px;
		width: 100%;
		background-color: #fff;
	}

	.btn-edit-pro{
		margin: 25px auto;
		width: 200px;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.input-label{
		color: $C_TEXT;
		font-size: 14px;
	}

	.selectContainer{
		margin: 20px 5px 0 5px;
		text-align: left;
	}

	form{
		.error-container{
			color: $ERROR_COLOR;
			text-align: left;
			font-size: 12px;
			margin-bottom: 10px;
			margin-top: 0px;
			.icon-error{
				margin-right: 5px;
			}
		}

		.error-alert{
			background-color: #f5efee;
		    padding: 7px;
		    text-align: left;
		    border-left: 3px solid $ERROR_COLOR;
		    color: $ERROR_COLOR;
		}
	}

	.error-alert{
		font-size: 14px;
		min-height: 30px;
	}

	.container-parent-change-pwd{
		margin-top: 30px;
	}

	.container-parent-personal-data{
		-webkit-box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.34);
		-moz-box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.34);
		box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.34);

		.container-title{
			background-color: $C_SECONDARY;

			background: rgba(3,199,179,1);
			background: -moz-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(3,199,179,1)), color-stop(100%, rgba(2,153,138,1)));
			background: -webkit-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
			background: -o-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
			background: -ms-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
			background: linear-gradient(135deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
			border-radius: 7px 7px 0 0;

			/*
			background-image: linear-gradient(45deg, #00b0a1 8.33%, #00a395 8.33%, #00a395 50%, #00b0a1 50%, #00b0a1 58.33%, #00a395 58.33%, #00a395 100%);
			background-size: 8.49px 8.49px;
			*/


			.title-sub-section{
				text-align: center;
			    font-family: $brandFontSerif;
			    font-size: 25px;
			    padding: 15px;
			    color: $C_MAIN;
			    /*font-weight: 700;*/
			    margin: 0;
			}

		}

	}



	.container-change-pwd{
		background-color: #fbfbfb;
		padding: 10px;
		margin-bottom: 20px;
		

		/*.title-sub-section{
			text-align: left;
			font-family: $brandFontSerif;
			font-size: 25px;
			padding: 20px;
			color: $C_TEXT;
			font-weight: 700;
			margin: 0;
		}*/

		.btn-change-password{
			margin: 20px;
			width: 200px;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	.container-baja{

		width: 100%;
		text-align: left;
		margin: 40px 0;
		.btn-baja{
			text-align: left;
			background-color: $C_TEXT_LIGHT;			
			text-transform: uppercase;
			color: $C_TEXT_SEMILIGHT;
			padding: 7px 30px;

			&:hover{
				color: #797979;
			}
		}

	}

	

}

/*
 END EDIT PROFILE PAGE
*/

.template-loading{
	min-height: calc(100vh - 60px);
	margin-top: 130px;
	text-align: center;
}

/*
 START CAMPAIGNS LIST PAGE
*/

.template-campaigns-list{

	margin-top: 100px;
	text-align: center;
	min-height: calc(100vh - 300px);
	

	@include phone{
		margin-top: 55px;
	}

	.filters-container{
				
		width: 100%;
		position: -webkit-sticky;
  		position: sticky;
  		top: 75px;
  		z-index: 1;		

  		@include tablet{
  			top: 60px;
  		}

  		@include phone{
  			top: 55px;
  		}
	}

	.filter-sub-container{

		background-color: #fff;
		margin: 0px auto;
		padding-bottom: 5px;		
		border-radius: 2px;

		@include large{
			width: 66.666667%;
		}

		@include desktop{
			width: 83%;
		}

		@include tablet{
			width: 95%;
		}

		@include phone{
			background-color: transparent;
		}

	}

	.range-input-container{

		
		width: 80%;
		display: inline-block;

		.range-label, .range-values {
			display: inline-block;
			width: 50%;
		}

		.range-label{
			text-align: left;
			font-size: 14px;
		}

		.range-values{
			text-align: right;
			font-size: 12px;
			color: #a7a7a7;
		}
	}

	.fixed-filters{
		box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
		background-color: #fff;
	}

	.input-range{
		.rc-slider-track{
			background-color: $C_SECONDARY;
		
		}

		.rc-slider-handle{
			border-color: $C_SECONDARY;
		}
		
	}

	.event-container-normal{
		width: 50%;

		@include phone{
			width: 100%;
		}
		
	}

	.event-container-premium{
		width: 100%;
	}

	.event-card-location{		
		font-size: 13px;
		color: $C_TEXT_SEMILIGHT;
		svg{
			font-size: 20px;
			margin-right: 5px;
			color: $C_SECONDARY;
		}
	}
	

	.container-marcas{
		margin: 0;
		height: 45px;
		img{
			max-width: 45px;
			margin: 0 5px;
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  			filter: grayscale(100%);
  			vertical-align: middle;
		}

	}

	.container-no-results{
		min-height: calc(100vh - 300px);
		margin: 60px auto;
		svg{
			font-size: 150px;
			color: $C_TEXT_SEMILIGHT;
			margin-bottom: 5px;

			@include phone{
				font-size: 95px;
			}
		}

		.no-results-content{
			position: relative;
			width: 260px;
			margin: 0 auto;
			text-align: right;

			@include phone{
				width: 230px;
			}
		}

		.text-no-result{
			text-align: left;
			display: inline-block;
			font-size: 20px;
			color: $C_TEXT_SEMILIGHT;
			vertical-align: bottom;
			margin-bottom: 0;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		@include phone{
			margin: 40px auto;
		}
	}	

	.container-lateral-banner-left{
		margin-top: 10px;
		text-align: right;		
		img{
			width: 160px;
		}
		@include desktop{
			display: none;
		}
	}

	.container-banner-fixed{
		position: fixed;
		top: 180px;
		z-index: -1;
	}

	.container-banner-fixed-left{
		position: fixed;
		top: 180px;
		left: calc(16% - 150px);
		z-index: -1;
	}

	.container-lateral-banner-right{
		margin-top: 10px;
		text-align: left;			

		img{
			width: 160px;
		}
		@include desktop{
			display: none;
		}
	}

	.banner-mobile{
		height: 90px;
		max-width: 100%;
		margin: 0 auto;
		@include phone{
			height: 60px;
		}
	}

	.snackbar-container{
		padding: 6px;
		div:first-child{
			padding: 0;
			width: 100%;
		}
	}

	.container-banner-mobile{
		svg{
			position: absolute;
		    right: 0;
		    top: 0;
		    background-color: #383838;
		    padding: 5px;
		    font-size: 14px;
		}
	}
	
	.mobile-banner-container{

		width: calc(100% - 13px);
		background-color: #383838;
		position: fixed;
		bottom: 0;
		padding: 6px;
		
		svg{
			position: absolute;
		    right: 0px;
		    top: 0;
		    background-color: #383838;
		    padding: 5px;
		    font-size: 14px;
		    color: $C_TEXT_LIGHT;
		}
		
	}

	.mobile-wrapper-container-hidden{
		display: none;
	}

	.container-card{

		min-height: 355px;
		padding: 15px;
			

		@include phone{
			min-height: 250px;
		}

		.container-card-border{
			-webkit-box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.44);
			-moz-box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.44);
			box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.44);
		}

		.container-image-card-background{
			
			height: 240px;
			width: 100%;
			background-image: url(https://media.motorprive.com/back_idees_b-01.png);
			background-position: center; 
			background-repeat: no-repeat; 
			background-size: cover;			
			position: relative;
			@include phone{
				height: 190px;
			}
			.container-image-card{
				position: absolute;
				top: 0;
				height: 240px;
				width: 100%;
				background-position: center; 
				background-repeat: no-repeat; 
				background-size: cover;

				@include phone{
					height: 190px;
				}

			}

			.container-days-until{
				text-align: right;
				font-size: 12px;				
				position: absolute;
				display: flex;
				height: 40px;
				background: linear-gradient(to bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
				width: 100%;
				/*bottom: 6px;
				left: 6px;
				*/
				top: 0;
				left: 0;

				p{
					margin: 0;
					padding: 5px 5px;
					color: #fff;
					-ms-transform: skew(-10deg); /* IE 9 */
  					-webkit-transform: skew(-10deg); /* Safari */
  					transform: skew(-10deg); /* Standard syntax */
				}				
			}

			.container-suscribers{
				position:absolute;
				right: 10px;
				top: 0;
				background-color: $C_SECONDARY;
				color: #fff;				
				padding: 10px;
				font-size: 11px;

				.number-suscribers{
					font-size: 35px;
					display: block;
					line-height: 30px;
				}

			}

			.container-gradient-bottom{
				position: absolute;
				display: flex;
				height: 50px;
				background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
				width: 100%;
				/*bottom: 6px;
				left: 6px;
				*/
				bottom: 0;
				left: 0;
			}

			.container-location{
				position: absolute;
				bottom: 5px;
				left: 50%;
				transform: translateX(-50%);
				background-color: #000;
				padding: 5px 20px 5px 20px;
				border-radius: 5px;
				text-align: left;
				font-size: 13px;
				color: $C_TEXT_SEMILIGHT;

				svg{
					font-size: 20px;
					margin-right: 5px;
					color: $C_SECONDARY;
				}			
			}

		}

		.container-info-card{
			min-height: 90px;
			border-top: 1px solid #a5a5a5;

			.text-info-card{

				text-align: left;

				.location-container{
					text-align: left;
					padding: 0 0 0 10px;
					position: relative;

					.map-event{
						img{
							width: 90px;
							position: absolute;
							bottom: -27px;
							left: 5px;
						}
					}
				}
				
				.title-event{
					margin: 0 0 0 5px;
					font-weight: 700;						
					text-align: left;					
					text-indent: 65px;
					padding-top: 3px;					
					font-size: 14px;
					text-transform: uppercase;
					letter-spacing: 1px;
					color: #868686;
				}

				.slogan-event{
					text-align: left;
					display: block;
					padding: 0px 0 5px 10px;
					margin: 15px 0 0 0;
					font-size: 16px;
					color: $C_TEXT;
					font-weight: 700;
					text-transform: uppercase;
					letter-spacing: 0.5px;

					overflow: hidden;    				
    				max-height: 50px;
    				
				}



				.cities-title-event{
					display: block;
					text-align: left;
					padding: 0px 0 5px 10px;
					font-size: 14px;
				}



			}

			.cities-event{
				font-size: 13px;
				color: $C_TEXT_SEMILIGHT;
				text-align: center;
				margin: 0 10px;
				padding: 10px 0;
				border-top: 1px solid #d4d4d4;
				svg{
					font-size: 20px;
					margin-right: 5px;
					color: $C_SECONDARY;
				}					
			}

			.container-brands{
				text-align: right;
				img{
					max-width: 60px;
					padding: 5px;

					@include phone{
						max-width: 45px;
					}
				}
				@include phone{
					text-align: center;
				}
			}
		}
		

	}

}

.filters-mobile-window{

	.range-input-container{
		
		width: 100%;
		display: inline-block;

		.range-label, .range-values {
			display: inline-block;
			width: 50%;
		}

		.range-label{
			text-align: left;
			font-size: 14px;
		}

		.range-values{
			text-align: right;
			font-size: 12px;
			color: #a7a7a7;
		}

	}

	.input-range{

		.rc-slider-track{
			background-color: $C_SECONDARY;		
		}

		.rc-slider-handle{
			border-color: $C_SECONDARY;
		}
		
	}
}


.container-access-forbidden{

	min-height: calc(100vh - 300px);
	margin: 40px auto;
	padding: 15px;
	text-align: center;
	
	button{
		margin-top: 20px;
	}

	.container-big-letters{

		font-size: 135px;
		color: $C_TEXT_LIGHT;

		@include phone{
			font-size: 75px;
		}

	}

	.content-access-forbidden{

		position: relative;

	}

	.container-text-forbidden{

		background-color: #f3f3f3;
		position: absolute; 
		padding: 5px;
		bottom: 32%;
		left: 50%;
		transform:translateX(-50%);
		margin: 10px auto 0 auto;
		font-size: 16px;
		color: $C_TEXT_SEMILIGHT;

		@include phone{
			position: relative; 
		}
		
		p{
			margin: 0;
		}

	}
}

.container-btn-interested{
	position: fixed;
	bottom: 0;
	
	height: 75px;
	width: 100%;

	background-color: #fff ;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);



    .container-interested{
    	position: relative;
    	height: calc(100% - 30px);
    	padding: 15px;
    	vertical-align: middle;

    	.name-interested{
    		display: inline-block;
    		font-family: $brandFontSerif;
    		font-size: 30px;
    		margin: 0;
    		margin-top: 5px;
    		text-overflow: ellipsis;
    		white-space: nowrap; 
    		overflow: hidden;
    		max-width: 800px;

    		@include tablet{
    			max-width: 400px; 
    		}

    		@include phone{
    			font-size: 20px;    			
    			max-width: 150px;    			
    			margin-top: 10px;
    		}
    	}

    	button{
    		position: absolute;
    		right: 0;
    		margin-right: 15px;
    	}
    }

    .btn-interested{
    	font-size: 12px;
    	letter-spacing: 1px;
    	border-radius: 3px;
    	padding: 15px 8px;
    	min-width: 175px;
    	outline: none;
  		border: 0;
  		text-transform: uppercase;
    }


}


/*
 END CAMPAIGNS LIST PAGE
*/

/*
	START PAGE NOT FOUND
*/

	/* Safari 4.0 - 8.0 */
@-webkit-keyframes numberAnimation {
  0%   { color: transparent; top: 30px; }  
  100%  { color: $C_TEXT_LIGHT; top: 0px; }
  
}

/* Standard syntax */
@keyframes numberAnimation {
  0%   { color: transparent; top: 30px; }  
  100%  { color: $C_TEXT_LIGHT; top: 0px; }
  
}


.template-page-not-found{

	margin-top: 115px;
	min-height: calc(100vh - 300px);
	margin-bottom: 50px;
	
	.container-page-not-found{
		color: $C_TEXT_SEMILIGHT;
		padding: 50px 15px 0 15px;
		text-align: center;
	}

	.firstNumber{
		position: relative;
		top: 30px;
		color: transparent;
		-webkit-animation-name: numberAnimation; /* Safari 4.0 - 8.0 */
	    -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
	    -webkit-animation-iteration-count: 1; /* Safari 4.0 - 8.0 */
	    animation-name: numberAnimation;
	    animation-duration: 1s;
	    animation-iteration-count: 1;
	    -webkit-animation-fill-mode: forwards;
	    animation-fill-mode: forwards;
	}

	.secondNumber{
		position: relative;
		top: 30px;
		color: transparent;
		-webkit-animation-name: numberAnimation; /* Safari 4.0 - 8.0 */
	    -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
	    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
	    animation-name: numberAnimation;
	    animation-duration: 1s;
	    animation-iteration-count: 1;
	    animation-delay: 0.5s;
	    -webkit-animation-fill-mode: forwards;
	    animation-fill-mode: forwards;
	}

	.thirdNumber{
		position: relative;
		top: 30px;
		color: transparent;
		-webkit-animation-name: numberAnimation; /* Safari 4.0 - 8.0 */
	    -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
	    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
	    animation-name: numberAnimation;
	    animation-duration: 1s;
	    animation-iteration-count: 1;
	    animation-delay: 0.7s;
	    -webkit-animation-fill-mode: forwards;
	    animation-fill-mode: forwards;
	}



	.container-error-number{
		margin: 0;
		font-size: 120px;
		svg{
			font-size: 80px;
			margin: 0 5px;
		}
	}
}

/*
	END PAGE NOT FOUND
*/

/*
	START PAGE LANDING
*/


.template-landing-campaign{
	margin-top: 125px;
	min-height: calc(100vh - 300px);

	@include phone{
		margin-top: 110px;
	}

	.container-image-campaign{
		margin: 15px 25px 15px 10px;	
		height: 400px;

		.container-image{
			width: 100%;
			height: 100%;

			.image-campaign{
				width: 100%;
    			height: 100%;
    			background-size: cover;
    			background-position: 50% 50%;
    			background-repeat: no-repeat;
			}
		}

		@include phone{
			margin: 0;
		}
	}

	.container-title-campaign{
		margin: 15px 5px 15px 25px;	
		height: 400px;

		.container-title{
			height: 100%;
			background-color: #f2f2f2;
			.title-campaign{
				padding: 15px;

				div{
					font-size: 50px;
					font-family: $brandFontSerif;
					text-align: center;					
				    display: -webkit-box;
				    width: 100%;
				    height: 190px;
				    margin: 0 auto;				    
				    -webkit-line-clamp: 3;
				    -webkit-box-orient: vertical;
				    overflow: hidden;
				    text-overflow: ellipsis;

				    p{
				    	text-align: center;
				    	width: 100%;
    					margin: 0;
				    }

				}
			}

			.more-main-info{
				.dates-event{
					text-align: center;
					font-size: 16px;
					color: $C_TEXT_SEMILIGHT;
				}
			}
		}

		.icons-campaign{
			color: $C_SECONDARY;
			padding: 15px;
			svg{
				margin: 1px;
				font-size: 10px;
			}
			span{
				
				font-size: 12px;
				display: block;
			}
		}

	}

	hr{
		height: 1px;
	    color: $C_TEXT_LIGHT;
	    background-color: $C_TEXT_LIGHT;
	    border: none;
	
	    @include phone{
			margin: 0 15px;
		}
	}

	.container-title-campaign-mobile{
		margin: 15px;	

		.container-title{
			.title-campaign{
				
				h1{
					text-align: left;
					text-overflow: ellipsis;
		    		white-space: nowrap; 
		    		overflow: hidden;
		    		max-width: 300px;
				}				
			}

			.dates-event{
				color: $C_TEXT_SEMILIGHT;
				font-size: 14px;
			}
		}

	}


	.container-info-event{
		.section-description{

			margin: 72px auto;

			@include phone{
				margin: 50px auto;

				.text-description{
					padding: 0 15px;
				}
			}
		}

		.section-ubicacion{
			margin: 72px auto;
			font-size: 15px;

			@include phone{
				margin: 50px auto;
			}
			.conce-time-table{
				font-size: 14px;
				margin-left: 20px;
				margin-bottom: 30px;
			}

			.conce-phone{
				margin-left: 20px;
				font-size: 14px;
			}

			.ubication-event{
				padding-left: 10px;

				@include phone{
					padding: 0 15px;
					font-size: 14px;
				}

				svg{
					color: $C_SECONDARY;
					margin-right: 5px;
					font-size: 20px;
				}
			}
		}

		.title-section{
			color: $C_TEXT;
			font-size: 25px;

			@include phone{
				font-size: 20px;
				padding: 0 15px;
			}

		}
		
		.brands-section{
			margin: 72px auto;

			@include phone{
				margin: 50px auto;
			}

			.brands-event{
				img{
					width: 65px;
					margin: 0 5px;
					/*-webkit-filter: grayscale(100%); 
  					filter: grayscale(100%);
  					*/

  					@include phone{
  						width: 55px;
  					}
				}

				@include phone{
					
					padding: 0 15px;
				}

			}
		}

		.section-conce{

			margin: 72px auto;

			@include phone{
				margin: 50px auto;
			}

			.conce-name-info{
				padding-left: 10px;

				a{
					color: #000;
					font-weight: 700;
					&:hover{
						color: $C_SECONDARY;
					}
				}

				@include phone{
					padding: 0 15px;
				}

				img{
					max-width: 150px;
				}
				
			}

			.conce-address{
				padding-left: 10px;
				font-size: 14px;
				color: $C_SECONDARY;
				@include phone{
					padding: 0 15px;
				}

			}

			.conce-phone{
				padding-left: 10px;
				font-size: 14px;
				svg{
					margin-right: 5px;
					font-size: 16px;
					color: $C_SECONDARY;
				}

				@include phone{
					padding: 0 15px;
				}


			}

			.conce-time-table{
				padding-left: 10px;
				font-size: 14px;

				@include phone{
					padding: 0 15px;
				}


			}
		}

	}	

	
	
}


.template-event-landing{

	margin-top: 125px;
	min-height: calc(100vh - 300px);

	@include phone{
		margin-top: 110px;
	}

	.container-image-campaign{
		
		height: 300px;

		.container-image{
			width: 100%;
			height: 100%;

			.image-campaign{
				width: 100%;
    			height: 100%;
    			background-size: cover;
    			background-position: 50% 50%;
    			background-repeat: no-repeat;
			}
		}

		@include phone{
			margin: 0;
			height: 150px;
		}
	}

	.section-element{
		margin: 60px auto;

		@include phone{
			padding: 0 15px;
			margin: 40px auto;
		}
	}

	.btn-section{
		text-align: center;

		a, button{
			padding: 10px 27px;
			font-size: 16px;
		}
	}

	.container-information{
		

		.title-event{
			font-size: 40px;
			font-family: $brandFontSerif;	
			margin-bottom: 10px;		
		}

		.dates-event{
			color: $C_TEXT_SEMILIGHT;
			font-size: 14px;
			margin-top: 30px;
		}

		.brands-event{
			img{
				width: 75px;
				margin: 15px;

				@include phone{
					margin: 10px;
					width: 65px;
				}
			}
		}

		.description-event{
			font-size: 15px;
		}

		.concesionario-event{
			font-size: 15px;
		}

		.title-section{
			font-size: 18px;
			font-weight: 700;
		}

		hr{
			height: 1px;
		    color: $C_TEXT_LIGHT;
		    background-color: $C_TEXT_LIGHT;
		    border: none;
		
		    @include phone{
				margin: 0 15px;
			}
		}

		.ubication-section{
			margin: 50px auto;
			font-size: 15px;
			.ubication-event{
				svg{
					color: $C_SECONDARY;
					margin-right: 10px;
				}
			}

			.conce-time-table{
				font-size: 14px;
				margin-left: 20px;
				margin-bottom: 30px;

			}

			.conce-phone{
				padding-left: 20px;
				font-size: 14px;
				svg{
					margin-right: 5px;
					font-size: 16px;
					color: $C_SECONDARY;
				}

				@include phone{
					padding: 0 15px;
				}

			}
		}

	}

	.container-banner-lateral{
		text-align: right;
		margin-top: 50px;
	}
}

.container-banner-mobile{
	svg{
		position: absolute;
	    right: 0;
	    top: 0;
	    background-color: #383838;
	    padding: 5px;
	    font-size: 14px;
	}
}
	
.mobile-banner-container{

	width: calc(100% - 13px);
	background-color: #383838;
	position: fixed;
	bottom: 0;
	padding: 6px;
	
	svg{
		position: absolute;
	    right: 0px;
	    top: 0;
	    background-color: #383838;
	    padding: 5px;
	    font-size: 14px;
	    color: $C_TEXT_LIGHT;
	}
	
}

.mobile-wrapper-container-hidden{
	display: none;
}

.banner-mobile{
	height: 90px;
	max-width: 100%;
	margin: 0 auto;
	@include phone{
		height: 60px;
	}
}

/*
	END PAGE LANDING
*/

.wrapper-container-countdown{

	margin: 50px 10px;

	.text-countdown{
		text-align: center;
		
		font-size: 18px;
		margin: 10px;
	}

	.container-countdown{
	
		text-align: center;
		
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;

		.container-countdown-element{

			display: inline-block;		
			padding: 15px;

	    	@include phone{
	    		margin: 0;
	    		padding: 10px;
	    	}

			.number-countdown{
				font-weight: 700;
			    font-size: 30px;
			    margin: 0;
			}

			.time-element-countdown{
				margin: 0;
	    		font-size: 14px;
	    		text-transform: uppercase;

	    		@include phone{
		    		font-size: 11px;
		    		
		    	}
			}
		}

		.container-coming-soon{
			font-weight: 700;
			font-size: 30px;
		}

		.Countdown-col-element{
			margin: 10px;
			position: relative;
			min-height: 400px;
			strong{
				position: absolute;
				top: 0;
			}
			span{
				position: absolute;
				bottom: 0;
			}
		}

		.closed-event-text{
			font-weight: 700;
			font-size: 20px;
		}
	}

}


.form-more-info{

	input{

		border: 1px solid #A2A2A2;  
		color: #505050;
		margin: 0;
		/*width: 100%;*/
		width: -webkit-calc(100% - 24px);
    	width:    -moz-calc(100% - 24px);
    	width:         calc(100% - 24px);
    	height: 25px;
	}

	input[type='checkbox']{
		

		border: 1px solid #A2A2A2;  
		color: #505050;
		margin: 0;
		height: 20px;
		width: 20px;
		display: inline-block;		
		position: relative;
		top: 5px;
	}

	select{
		border: 1px solid #A2A2A2;  
		color: #505050;
		width: 100%;
		height: 40px;
	}
	

	textarea{
		width: -webkit-calc(100% - 5px);
    	width:    -moz-calc(100% - 5px);
    	width:         calc(100% - 5px);
    	border: 1px solid #A2A2A2; 
    	color: rgb(0, 0, 0) !important;
    	border-radius: 5px;
    	min-height: 70px;
	}


	.input-label{
		color: $C_TEXT;
		font-size: 14px;

		a{
			color: $C_SECONDARY;
		}
	}

	.inputContainer{
		margin: 10px auto;
	}

	.inputContainerCheckbox{
		label{
			font-size: 12px;
		}
	}

	.error-container{
		color: $ERROR_COLOR;
		text-align: left;
		font-size: 12px;
		margin-bottom: 10px;
		margin-top: 2px;
		.icon-error{
			margin-right: 5px;
		}
	}

}


/*
	START PAGE REGISTER
*/


.template-sign-up{
	
	margin-top: 50px;
	min-height: calc(100vh - 300px);

	.container-message-ok{
		background-color: #e2fffc;
		border-left: 3px solid $C_SECONDARY;		
		padding: 10px;
		p{
			font-size: 14px;
		}


	}

	.title-text{
			font-weight: 700;
			font-size: 40px;
			text-align: center;
			margin: 0;
		}


	.container-sign-up{
		background-color: #585858;
		text-align: center;
		padding: 20px 0;
		margin: 20px 0 20px 0;

		.facebook-btn{
			@include phone{
				width: 90%;
			}
		}

		.google-btn{
			@include phone{
				width: 90%;
			}
		}

		.separator-with-text{

			color: #fff;
	    	height: 40px;
	    	line-height: 40px;
	    	text-align: center;    
	    	width: 80%;
	    	margin: 0 auto;

	    	&:before, &:after {
	    		content: '';
			    display: inline-block;
			    position: relative;
			    height: 1px;
			    width: 50%;
			    background-color: #fff;
			    vertical-align: middle;
	    	}

	    	&:before{
	    		right: 0.5em;
	    		margin-left: -50%;
	    	}

	    	&:after{
	    		left: 0.5em;
	    		margin-right: -50%;
	    	}

		}

	}

	.container-form-sign-up{
		

		.social-sign-up{
			text-align: center;
		}

		.signin-form{
			text-align: center;
			
		}
		
		.inputContainer{
			margin: 5px auto;
			
			input{
				margin: 0 auto;
				font-size: 14px;
			    font-weight: normal;
			    color: #d4d4d4;
			    background-color: transparent;
			    border: 1px solid #e5e5e5;
			    width: 80%;
			    height: 25px;
			    padding: 6px 12px;
			    border-radius: 3px;
			    display: block;
			    @include placeholder {
				    font-style:italic;
				    color: #afafaf;				   
				}
			}

		}

		.btn-signin{
			/*width: 100%;*/
			padding: 12px 14px;
			margin: 20px auto;
			
		}

	}

}


/*
	END PAGE REGISTER
*/


.template-page{
	margin-top: 50px;
	min-height: calc(100vh - 300px);
}


/* START LANDING NOT FOUND */


/* Safari 4.0 - 8.0 */
@-webkit-keyframes ghostAnimation {
  0%   { color: $C_TEXT_SEMILIGHT; top:0px;}  
  50%  { color: $C_TEXT_LIGHT; top:40px;}
  100% { color: $C_TEXT_SEMILIGHT; top:0px;}
}

/* Standard syntax */
@keyframes ghostAnimation {
  0%   { color: $C_TEXT_SEMILIGHT; top:0px;}  
  50%  { color: $C_TEXT_LIGHT; top:40px;}
  100% { color: $C_TEXT_SEMILIGHT; top:0px;}
}


.template-landing-not-found{

	margin-top: 150px;
	min-height: calc(100vh - 300px);

	.container-no-results{

		min-height: calc(100vh - 300px);
		margin: 60px auto;

		svg{
			font-size: 150px;
			color: $C_TEXT_SEMILIGHT;
			margin-bottom: 5px;
			position: relative;
			-webkit-animation-name: ghostAnimation; /* Safari 4.0 - 8.0 */
		    -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
		    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
		    animation-name: ghostAnimation;
		    animation-duration: 10s;
		    animation-iteration-count: infinite;

			@include phone{
				font-size: 95px;
			}
		}

		.no-results-content{
			position: relative;
			width: 260px;
			margin: 0 auto;
			text-align: center;

			@include phone{
				width: 230px;
			}
		}

		.text-no-result{
			text-align: center;			
			font-size: 20px;
			margin-top: 50px;
			color: $C_TEXT_SEMILIGHT;			
		}

		@include phone{
			margin: 40px auto;
		}
	}		
}

/* END LANDING NOT FOUND */

/* START INTERESTS PAGE */


.template-interests-page{

	margin-top: 150px;

	@include phone{
		margin-top: 100px;
	}

	.container-block-interests{
		background-color: $C_MAIN_LIGHT;
		margin-bottom: 30px;
		padding-bottom: 30px;

		.title-sub-section{
			text-align: center;
			font-family: $brandFontSerif;
			font-size: 30px;
			padding: 15px;
			color: $C_TEXT;
			font-weight: 700;
			margin: 0;
		}

		.explanation-text{
			padding: 15px;
			margin: 0;
			color: $C_TEXT;
			font-size: 14px;
			text-align: center;
		}

	}

	.container-gustos-primer{
		float:left;
		width: 50%;
		height: 100%;
	}

	.container-gustos-segon{
		float:left;
		width: 50%;
		height: 100%;
	}


	.container-loading{
		text-align: center;
	}

	.name-brand{
    	text-align: center;
    	font-size: 12px;
    	margin: 0;
    	color: $C_TEXT_SEMILIGHT;
    }
    .grid-brand-selected{
    	text-align: center;
    	margin: 30px 0;
    }

    .close-selected-brand{
    	svg{
    		font-size: 30px;
    		cursor: pointer;
    	}
    }

    .container-btn-tabs{
    	text-align: center;
    	margin-bottom: 50px;

    	@include phone{
    		margin-bottom: 30px;
    	}

    	button{
    		margin: 10px 10px;
    		padding: 40px 60px;
			font-size: 16px;
			outline: none;
			
			border-radius: 7px;
			/*
			border: 2px solid $C_SECONDARY;
			background-color: transparent;
			*/
			color: #d4d4d4;
			

			&:hover{
				background-color: rgba(0,0,0,.5);
				color: #fff;
			}
    	}

    	.btn-mis-coches{
    		background-image: url(https://www.motorprivee.com/media/btn-coches-01.jpg);
			background-position: center; 
			background-repeat: no-repeat; 
			background-size: cover;
    	}

    	.btn-mis-zonas{
    		background-image: url(https://www.motorprivee.com/media/btn-zonas.jpg);
			background-position: center; 
			background-repeat: no-repeat; 
			background-size: cover;
    	}


    	.selected{
    		background-color: $C_SECONDARY;
    		color: #fff;
    	}
    }
    .container-btn-interests{
    	
    	text-align: center;
    	margin-top: 20px;

    	.btn-save-interests{
    		/*width: 100%;
    		
    		*/
    		min-width: 200px;
    		min-height: 50px;
    	}	
    }
    
    hr{
    	margin: 0 20px;
    	border: 0.5px solid #d8d8d8;
    	/*color: $C_SECONDARY;*/
    }

    .sub-title-brands-tab{
    	margin-left: 20px;
    }
    
}


.container-btn-save-interests{
	position: fixed;
	bottom: 0;
	left: 0;
	height: 75px;
	width: 100%;

	background-color: #fff ;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);

	.container-interested{
    	position: relative;
    	height: calc(100% - 30px);
    	padding: 10px;
    	vertical-align: middle;

    	
    	/*button{
    		position: absolute;
    		right: 0;
    		margin-right: 15px;
    	}*/

    	.container-interests-user{
    		/*background-color: #f5f5f5;
    		border: 1px solid $C_TEXT_SEMILIGHT;
    		*/
    		border: 1px solid #f5f5f5;
    		width: 100%;
    		height: 50px;
    		padding: 5px;

    		overflow-y: scroll;
    		span{
    			font-size: 14px;
    		}
    	}
    }

    .btn-interested{
    	font-size: 12px;
    	letter-spacing: 1px;
    	border-radius: 3px;
    	padding: 15px 8px;
    	width: 175px;
    	outline: none;
  		border: 0;
  		text-transform: uppercase;
    }



    .sub-container-btn-save-interests{
    	width: 100%;
    	text-align: center;

    	@include phone{
    		text-align: center;
    	}

    	.btn-save-interests{

    		width: 100%;
    		min-height: 55px;
    		
    	}
    }
}

/* END INTERESTS PAGE */


/* START USER VEHICLES FORM */

.container-parent-vehicles-data{
	-webkit-box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.34);
	-moz-box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.34);
	box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.34);
	margin-top: 30px;

	.container-title{
		background-color: $C_SECONDARY;

		background: rgba(3,199,179,1);
		background: -moz-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(3,199,179,1)), color-stop(100%, rgba(2,153,138,1)));
		background: -webkit-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		background: -o-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		background: -ms-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		background: linear-gradient(135deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		border-radius: 7px 7px 0 0;

		/*
		background-image: linear-gradient(45deg, #00b0a1 8.33%, #00a395 8.33%, #00a395 50%, #00b0a1 50%, #00b0a1 58.33%, #00a395 58.33%, #00a395 100%);
		background-size: 8.49px 8.49px;
		*/


		.title-sub-section{
			text-align: center;
		    font-family: $brandFontSerif;
		    font-size: 25px;
		    padding: 15px;
		    color: $C_MAIN;
		    /*font-weight: 700;*/
		    margin: 0;
		}

	}

}

.vehicle-form{

	.title-vehicle{
		text-align: left;
		margin-bottom: 0;
		margin-left: 5px;
		color: $C_TEXT_SEMILIGHT;
	}

	.grid-check{
		text-align: left;
		padding: 20px 0 20px 0;
		.input-checkbox{
			display: inline-block;
			margin-left: 5px;
			input{
				width: 25px;
				height: 15px;
			}
		}

		label{
			font-size: 14px;
		}
	}
	

}



/* END USER VEHICLES FORM */

/* END USER ALERTS */

.template-alerts-page{

	margin-top: 150px;
	min-height: calc(100vh - 250px);


	@include phone{
		margin-top: 100px;
	}

	.container-parent-alert{
		-webkit-box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.44);
		-moz-box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.44);
		box-shadow: 0px 0px 8px 1px rgba(173,173,173,0.44);
		border-radius: 7px;
	}

	.container-loading{
		text-align: center;
	}

	.container-block-alerts{
		background-color: #fbfbfb;
		margin-bottom: 30px;
		padding: 10px 30px 30px 30px;
		text-align: center;
	}

	.container-title{
		background-color: $C_SECONDARY;

		background: rgba(3,199,179,1);
		background: -moz-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(3,199,179,1)), color-stop(100%, rgba(2,153,138,1)));
		background: -webkit-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		background: -o-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		background: -ms-linear-gradient(-45deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		background: linear-gradient(135deg, rgba(3,199,179,1) 0%, rgba(2,153,138,1) 100%);
		border-radius: 7px 7px 0 0;

		/*
		background-image: linear-gradient(45deg, #00b0a1 8.33%, #00a395 8.33%, #00a395 50%, #00b0a1 50%, #00b0a1 58.33%, #00a395 58.33%, #00a395 100%);
		background-size: 8.49px 8.49px;
		*/


		.title-section{
			text-align: center;
		    font-family: $brandFontSerif;
		    font-size: 25px;
		    padding: 15px;
		    color: $C_MAIN;
		    /*font-weight: 700;*/
		    margin: 0;
		}

	}
	

	.sub-title-section-cars{
		margin: 30px 0 0 0;
		padding: 5px;
		border-bottom: 1px solid #d4d4d4;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 1px;
	}

	.text-explanation{
		text-align: center;
		font-size: 20px;
		margin: 10px;
	}

	.btn-save-alerts{
		margin: 20px auto;

		@include phone{
			display: none;
		}
	}

	.container-btn-save-interests{
		display: none;

		@include phone{
			display: block;
		}
	}

}


/* END USER ALERTS  */


/*
 START PORTADA PAGE
*/

.template-portada{

	margin-bottom: 50px;


	.container-first-line{
		margin: 5px;
		background-color: blue;
		min-height: 200px;
		cursor: pointer;
		color: #fff;
	}

	.container-line{
		margin: 5px;		
		min-height: 200px;
		background-repeat: no-repeat;
		background-size: cover;		
		color: #fff;
		text-align: center;
		position: relative;
		/*
		background-position: center; 
		*/

		@include tablet{
			min-height: 170px;
		}

		@include phone{
			min-height: 175px;
		}

		.container-claim-text{
			
			margin: 0 auto;			
			line-height: 25px;
			letter-spacing: 1px;
			width: 100%;
			text-align: center;
			position: absolute;
			top: 40%;
			font-size: 14px;

			span{
				font-size: 26px;
			}

		}

		.first-claim{
			font-size: 18px;
			font-style: italic;
		}

		.last-claim{
			font-size: 18px;
		}

	}

	.template-default{

		.first-container{
			
			background-image: url(https://media.motorprive.com/proves/prova-smart.jpg);
		}

		.second-container{
			background-image: url(https://media.motorprive.com/proves/motorprive-claim.jpg);
		}

		.third-container{
			background-image: url(https://media.motorprive.com/proves/motorprive-claim-02.jpg);
		}

		.fourth-container{
			background-image: url(https://media.motorprive.com/proves/prova-citroen.jpg);
		}

		.fifth-container{
			background-image: url(https://media.motorprive.com/proves/prova-peugeot.jpg);
		}

		.sixth-container{
			background-image: url(https://media.motorprive.com/proves/prova-smart.jpg);
		}

		.seventh-container{
			background-image: url(https://media.motorprive.com/proves/prova-fiat.jpg);
		}

		.eighth-container{
			background-image: url(https://media.motorprive.com/proves/motorprive-claim-04.jpg);
		}
	}

	.template-nondefault{
		.first-container{
			background-image: url(https://media.motorprive.com/proves/prova-mercedes.jpg);
		}

		.second-container{
			background-image: url(https://media.motorprive.com/proves/prova-claim-05.jpg);
		}

		.third-container{
			background-image: url(https://media.motorprive.com/proves/prova-claim-05.jpg);
		}

		.fourth-container{
			background-image: url(https://media.motorprive.com/proves/prova-smart.jpg);
		}

		.fifth-container{
			background-image: url(https://media.motorprive.com/proves/prova-lexus.jpg);
		}

		.sixth-container{
			background-image: url(https://media.motorprive.com/proves/prova-bmw.jpg);
		}

		.seventh-container{
			background-image: url(https://media.motorprive.com/proves/audi-prova.jpg);
		}

		.eighth-container{
			background-image: url(https://media.motorprive.com/proves/prova-claim-05.jpg);
		}
	}

	

}


/*
 END PORTADA PAGE
*/



.template-login-campaign{
	margin-top: 0;
	margin-bottom: 50px;

	.container-bg{

		display: none;

		@include desktop{
			display: block;
			width: 100vw;
		    height: 100vh;
			background-repeat: no-repeat;
			background-size: cover;
			z-index: -10;
		    position: fixed;
		    left: 0px;
		    top: 0px;
		    bottom: auto;
		    right: auto;
		}
		

		/*
		background-repeat: no-repeat;
		background-size: cover;
		position: fixed;
	    left: 0px;
	    top: 0px;
	    width: 100vw;
	    height: 100vh;
	    bottom: auto;
	    right: auto;
	    z-index: -10;
	    */


	}
}

/*
 START STEPS PROFILE PAGE
*/

.template-container-steps-profile{

	margin-top: 150px;
	margin-bottom: 50px;

	.container-block-personal-data{
		text-align: center;
	}

	.template-steps-profile{

		font-size: 14px;	

		p{
			text-align: left;
			margin: 0 auto;		
			padding-bottom: 30px;
		}

		.message-status-steppers{
			text-align: center;
			font-weight: 700;
			font-size: 30px;
		}

		.container-icon-steps{

			text-align: center;
			padding-bottom: 0;

			.icon-steps-completed{
				font-size: 50px;
			}

		}

		

		.container-step-bar{
			background-color: #fff;
			border-top: 1px solid #d4d4d4;
			border-left: 1px solid #d4d4d4;
			border-right: 1px solid #d4d4d4;
			box-shadow: 0px 0px 8px 1px rgba(173, 173, 173, 0.25);
			padding: 30px 15px;
			text-align: left;
			font-size: 12px;
		}

		.container-step{
			background-color: #fff;
			border-top: 1px solid #d4d4d4;
			border-left: 1px solid #d4d4d4;
			border-right: 1px solid #d4d4d4;
			box-shadow: 0px 0px 8px 1px rgba(173, 173, 173, 0.25);
			padding: 30px 15px;
			text-align: left;
			font-size: 12px;
			cursor: pointer;

			&:hover{
				background-color: #f3f3f3;
			}

			a{
				color: #000;				
			}

			svg{
				font-size: 20px;
			}

			.inactive-step{
				color: #d4d4d4;
			}

			.active-step{
				color: $C_SECONDARY;
			}

			.title-step{
				
				font-weight: 700;

			}

			.explanation-step{

			}

			.icon-go{
				text-align: center;
				margin: auto;
			}

		}

		.step-done{
			background-color: #f3fffe;

			&:hover{
				background-color: #f3fffe;
			}

			.title-step{
				text-decoration-line: line-through;
			}
			
		}

		.last-container-step{
			border-bottom: 1px solid #d4d4d4;
		}

	}


}

.vehicles-form-popup{
	
	padding: 15px;


	.inputContainer{
		margin: 15px 5px 0 5px;
		text-align: left;		
	}

	.icon-title-section{
		margin: 20px 0 0 0;
    	font-size: 75px;
    	color: #d4d4d4;
	}

	.title-section{
		text-align: center;
		font-family: $brandFontSerif;
		font-size: 25px;
		margin: 0px 0 45px 0;
		color: $C_TEXT;
		font-weight: 700;
	}

	input{

		border: 1px solid #A2A2A2;  
		color: #505050;
		margin: 0;
		/*width: 100%;*/
		width: -webkit-calc(100% - 24px);
    	width:    -moz-calc(100% - 24px);
    	width:         calc(100% - 24px);
    	height: 25px;
    	background-color: #fff;
	}

	input:disabled{
	  border: 1px solid #e0e0e0;
	  color: #7d7d7d;
	  font-style: italic;
	  background-color: #d4d4d4;
	}

	select{
		border: 1px solid #A2A2A2;  
		color: #505050;
		height: 40px;
		width: 100%;
		background-color: #fff;
	}

	.btn-edit-pro{
		margin: 25px auto;
		width: 200px;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.input-label{
		color: $C_TEXT;
		font-size: 14px;
	}

	.selectContainer{
		margin: 20px 5px 0 5px;
		text-align: left;
	}

	form{
		text-align: center;
		.error-container{
			color: $ERROR_COLOR;
			text-align: left;
			font-size: 12px;
			margin-bottom: 10px;
			margin-top: 0px;
			.icon-error{
				margin-right: 5px;
			}
		}

		.error-alert{
			background-color: #f5efee;
		    padding: 7px;
		    text-align: left;
		    border-left: 3px solid $ERROR_COLOR;
		    color: $ERROR_COLOR;
		}
	}

}

.container-form-purchase{

	padding: 15px;

	.inputContainer{
		margin: 15px 5px 0 5px;
		text-align: left;		
	}

	.icon-title-section{
		margin: 20px 0 0 0;
    	font-size: 75px;
    	color: #d4d4d4;
	}

	.title-section{
		text-align: center;
		font-family: $brandFontSerif;
		font-size: 25px;
		margin: 0px 0 45px 0;
		color: $C_TEXT;
		font-weight: 700;
	}

	input{

		border: 1px solid #A2A2A2;  
		color: #505050;
		margin: 0;
		/*width: 100%;*/
		width: -webkit-calc(100% - 24px);
    	width:    -moz-calc(100% - 24px);
    	width:         calc(100% - 24px);
    	height: 25px;
    	background-color: #fff;
	}

	input:disabled{
	  border: 1px solid #e0e0e0;
	  color: #7d7d7d;
	  font-style: italic;
	  background-color: #d4d4d4;
	}

	select{
		border: 1px solid #A2A2A2;  
		color: #505050;
		height: 40px;
		width: 100%;
		background-color: #fff;
	}

	.btn-edit-pro{
		margin: 25px auto;
		width: 200px;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.input-label{
		color: $C_TEXT;
		font-size: 14px;
	}

	.selectContainer{
		margin: 20px 5px 0 5px;
		text-align: left;
	}

	form{
		text-align: center;
		.error-container{
			color: $ERROR_COLOR;
			text-align: left;
			font-size: 12px;
			margin-bottom: 10px;
			margin-top: 0px;
			.icon-error{
				margin-right: 5px;
			}
		}

		.error-alert{
			background-color: #f5efee;
		    padding: 7px;
		    text-align: left;
		    border-left: 3px solid $ERROR_COLOR;
		    color: $ERROR_COLOR;
		}
	}
}


.alerts-form-popup{
	
	padding: 15px;

	.container-block-alerts{
		
	}

	.text-explanation{
		text-align: center;
		font-size: 16px;
		margin: 5px;
	}

	.btn-save-alerts{
		margin: 20px auto;
		display: block;
		
	}

}

/*
 END STEPS PROFILE PAGE
*/




