
.custom-template-landing-campaign{
	
	min-height: calc(100vh - 300px);

	@include tablet{
		margin-top: 35px;
	}	

}



.custom-landing{

	color: #3a3a3a;




	.container-title-custom-landing{

		@include phone{
			display: none;
		}

		@include tablet{
			display: none;
		}


		h1{
			font-family: $brandFontSerif;
			text-align: center;
			font-size: 40px;
		}		

		h2{
			text-align: center;
			margin-top: 30px;
			svg{
				color: $C_SECONDARY;
				margin-right: 15px;
			}
		}
	}

	.main-container-info{
		position: relative;
		min-height: 470px;

		@include tablet{
			min-height: 350px;
		}

		@include phone{
			min-height: 250px;
		}
	}

	.container-top-info{		

		position: absolute;
		top: 100px;
		width: 100%;		
		text-align: center;

		@include desktop{
			top: 180px;
		}

		@include tablet{
			top: 100px;

		}	

		.main-car-image{
			max-width: 900px;	

			@include desktop{
				max-width: 600px;

			}

			@include tablet{
				max-width: 600px;

			}		

			@include phone{
				max-width: 300px;
			}	
		}

		
		
	}



	.back-image-main-container{		
		
		/*margin-top: 20px;			
		background-position: center; 
		background-repeat: no-repeat; 
		background-size: 80%;
		
		
		min-height: 350px;
		margin-left: auto;
		margin-right: auto;
		
		@include desktop{
			background-position: center;  

		}
		
		@include tablet{
			min-height: 0;
			height: 300px;
			background-position: center;

		}

		@include phone{
			min-height: 0;
			height: 200px;
			margin-top: 50px;
			background-size: 120%;
			background-position: center;
		}*/
		background-position: center; 
		background-repeat: no-repeat; 
		background-size: cover;
		min-height: 500px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0;
		
		
		@include tablet{
			min-height: 300px;			
			margin-top: 0;
		}

		@include phone{
			min-height: 200px;
			margin-top: 0;
		}
		

		.main-info-event{
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(255,255,255, 0.6);

			@include phone{
				top: -30px;
			}

			.info-event-container{
				margin: 20px;
				text-align: center;
				
				

				svg{
					color: $C_SECONDARY;
					font-size: 25px;

					@include phone{
						font-size: 20px;
					}
				}

				.date-event-custom-landing{
					color: #000;					
					font-size: 16px;
					margin-bottom: 20px;
				}

				.location-event-custom-landing{
					color: #000;					
					font-size: 16px;
					margin-bottom: 20px;
					
				}

				.container-second-info{

					@include phone{
						display: none;
					}
					
				}

				.brands-event-custom-landing{
					img{
						max-height: 65px;
					}
					
				}
			}
		}

	}

	.container-mobile-info{
		display: none;

		@include phone{
			display: block;
			text-align: center;

			svg{
				color: $C_SECONDARY;				
				font-size: 20px;
			}

			.brands-event-custom-landing{
					img{
						max-height: 65px;
					}
					
				}
		}
	}

	.incritos-container{
			
		text-align: center;
		position: absolute;
		top: 0;
		right: 20px;

		@include phone{
			top: -30px;
		}

		.container-share-inscritos{
			@include tablet{
				display: none;
			}
		}

		.inscrito-main-container{
			color: #000;
			display: inline-block;
			text-align: center;
			position: relative;
			.number-inscritos-container{
				font-size: 100px;		

				@include phone{
					font-size: 50px;
				}			
			}

			.inscrito-text-container{
				text-transform: uppercase;
				font-size: 14px;
				margin-top: -20px;
			}

		}
	}

	.mobile-container-title-custom-landing{
		/*background-color: #f3f3f3;*/
		.landing-title-content{
			font-weight: 700;
			text-align: center;
			font-size: 35px;

			@include phone{
				font-size: 28px;
			}
		}		

		h2{
			text-align: center;
			margin-top: 30px;
			font-size: 18px;
			margin-bottom: 0;
			svg{
				color: $C_SECONDARY;
				margin-right: 15px;
			}
		}


	}

	.content-custom-landing{
		padding: 80px auto;

		@include phone{
			margin: 15px;
		}
	}

	.container-btn-advice{
		text-align: center;
		padding: 0 15px;
		margin-top: 80px;

		@include tablet{
			margin-top: 15px;
		}

		@include phone{
			margin-top: 0px;
		}
		.btn-interested{
			font-size: 15px;
		    letter-spacing: 1px;
		    border-radius: 3px;
		    padding: 15px 23px;
		    min-width: 175px;
		    outline: none;
		    border: 0;
		    text-transform: uppercase;
		    margin-bottom: 50px;
		    animation: shadow-pulse 1s infinite;
		}

		.btn-interested-top{
			font-size: 15px;
		    letter-spacing: 1px;
		    border-radius: 3px;
		    padding: 15px 23px;
		    min-width: 175px;
		    outline: none;
		    border: 0;
		    text-transform: uppercase;
		    animation: shadow-pulse 1s infinite;
		}

		.btn-interested-product{
			font-size: 14px;
		    letter-spacing: 1px;
		    border-radius: 3px;
		    padding: 10px 20px;
		    
		    outline: none;
		    border: 0;
		    text-transform: uppercase;		    
		}

	}



	.container-btn-interested-custom{
		position: fixed;
		bottom: 0;
		z-index: 200;
		height: 75px;
		width: 100%;

		background-color: #fff ;
	    -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
		box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);

	    .container-interested{
	    	position: relative;
	    	height: calc(100% - 30px);
	    	padding: 10px 15px;
	    	vertical-align: middle;

	    	@include phone{
	    		text-align: center;
	    	}

	    	.name-interested{
	    		display: inline-block;
	    		/*font-family: $brandFontSerif;*/
	    		font-size: 20px;
	    		/*margin: 0;	
				max-width: 800px;
	    		*/    		
	    		text-overflow: ellipsis;
	    		white-space: nowrap; 
	    		overflow: hidden;
	    		margin: 10px 0 0 0;	
				max-width: 1000px;

	    		@include tablet{
	    			max-width: 420px; 
	    		}

	    		@include phone{
	    			font-size: 20px;    			
	    			max-width: 150px;
	    			display: none;	    			
	    		}

	    		p{
	    			display: inline;
	    		}

	    		.event-name{
	    			display: inline-block;
	    			height: 60px;
	    			vertical-align: middle;

	    			
	    		}

	    		.event-seller-tell{
	    			display: inline-block;
	    			height: 60px;
	    			vertical-align: middle;
	    			font-size: 14px;
	    			margin-left: 20px;
	    			font-style: italic;
	    		}

	    		.event-date-final{
	    			font-size: 16px;
	    			font-family: $brandFont;
	    			display: inline-block;
	    			height: 60px;
	    			vertical-align: middle;
	    			svg{
	    				color: $C_SECONDARY;
	    				margin-left: 30px;
	    				margin-right: 10px;
	    			}
	    			@include tablet{
	    				display: none;
	    			}
	    		}

	    		.event-location{
	    			font-size: 16px;
	    			font-family: $brandFont;
	    			display: inline-block;
	    			height: 60px;
	    			vertical-align: middle;
	    			margin-right: 20px;
	    			svg{
	    				color: $C_SECONDARY;
	    				margin-left: 30px;
	    				margin-right: 10px;
	    			}
	    			@include desktop{
	    				display: none;
	    			}
	    		}

	    		.event-brands{
	    			display: inline-block;
	    			img{
	    				height: 50px;
	    				display: inline-block;
	    				margin: 0 10px;
	    			}
	    		}

	    	}

	    	button{
	    		position: absolute;
	    		right: 0;
	    		margin-right: 15px;

	    		@include phone{
	    			position: static;
	    			width: 100%;
	    		}
	    	}



	    }

	    .btn-interested-bar{
	    	font-size: 14px;
	    	letter-spacing: 1px;
	    	border-radius: 3px;
	    	padding: 15px 8px;
	    	min-width: 250px;
	    	outline: none;
	  		border: 0;	  		
	  		background-color: #00009A;	
	  			
	  		&:hover{
	  			background-color: #0101ab;
	  		}

	  		@include tablet{
	  			font-size: 18px;
	  		}
	    }
	}

	.container-sep{

		-webkit-box-shadow: 0px -12px 13px -2px rgba(0,0,0,0.36); 
		-moz-box-shadow: 0px -12px 13px -2px rgba(0,0,0,0.36); 
		box-shadow: 0px -12px 13px -2px rgba(0,0,0,0.36);
		border-top: 1px solid #f3f3f3;
		margin-top: 40px; 
		min-height: 70px;
		
		/*background-color: #f3f3f3;*/

	}

	.container-sep-bottom{
		margin-bottom: 40px; 
		min-height: 50px;
		
		box-shadow: 0 20px 20px -20px rgba(0,0,0,0.26);
		border-bottom: 1px solid #f3f3f3;
		/*background-color: #f3f3f3;*/
	}

	.container-fluid-slogan{
		/*background-color: #f3f3f3;*/
		margin: 0;
	}


	.container-fluid-btn-interested{
		margin: 30px auto;
		text-align: center;
	}


	.container-fluid-description{
		padding: 80px 0;
		background-image: url(https://media.motorprive.com/cubes-left-center.png), url(https://media.motorprive.com/cubes-right.png);
		background-repeat: no-repeat, no-repeat;
		background-position: left top, right top;


		@include phone{
			background-size: 100% auto;
		}
		
	}

	.container-fluid-btns{
		background-image: url(https://media.motorprive.com/cubes-left-center.png), url(https://media.motorprive.com/cubes-right.png);
		background-repeat: no-repeat, no-repeat;
		background-position: left top, right top;
		padding-bottom: 70px;

	}

	.container-fluid-countdown{		
		margin-bottom: 30px;		
	}

	.container-top-button{
		position: fixed;
		/*bottom: 175px;*/
		bottom: 100px;
		right: 30px;
		opacity: 0;
		z-index: 2000;
		-webkit-transition: opacity 0.5s ease-in-out;
    	-moz-transition: opacity 0.5s ease-in-out;
    	transition: opacity 0.5s ease-in-out;

		@include tablet{
			right: 5px;
			bottom: 90px;
			/*bottom: 170px;*/
		}


		span{
			background: #fff;
			color: $C_SECONDARY;
			padding: 7px 13px;
			border: 7px;
			cursor: pointer;
			border: 2px solid $C_SECONDARY;

			&:hover{
				background-color: $C_SECONDARY;
				color: #fff;
			}

			@include phone{
				padding: 5px 10px;
			}

		}
	}

	.btn-up-visible{
		opacity: 1;
	}

	.container-main-info-event{
		/*background-color: #f4fffd;
		padding: 70px 0;*/
		margin-bottom: 40px;
	}

	.container-item{
		text-align: center;
		background-color: #fff;
		margin: 5px;
		padding: 40px 10px;
		border-radius: 7px;
		min-height: 160px;

		@include tablet{
			margin: 5px 15px;
		}

		@include phone{
			min-height: 150px;
		}

		p{
			svg{
				color: $C_SECONDARY;
				font-size: 50px;

				@include phone{
					font-size: 40px;
				}
			}

		}

		@include phone{
			padding: 20px 10px;
		}

	}

	.container-second-col{

		@include tablet{
			margin-top: 20px;
		}

	}

	.container-get-registered{

		background-color: #00009A;
		width: 100%;
		text-align: center;
		padding: 80px 0;
		margin: 30px auto;

		.text-get-registered{
			margin: 0;
			color: #fff;
			font-size: 20px;
		}

		.btn-get-registered{
			margin: 30px auto;
			border: 2px solid #fff;
			background-color: transparent;
			color: #fff;
			padding: 10px 40px;
			font-size: 14px;
			border-radius: 25px;
			letter-spacing: 2px;
			
			&:hover{
				background-color: #fff;
				color: #00009A;
			}
		}

	}



}

.text-closed-event{
	font-size: 14px;
	background-color: #d4fdf9;
	border-left: 2px solid $C_SECONDARY;
	padding: 10px;

}

.div-loader{
	position: absolute;
	top: 0%;
	left: 0%;
	background-color: rgba(255, 255, 255, 0.6);;
	width: 100%;
	height: 100%;
	text-align: left;
	.container-loader{
		position: relative;
		top: 45%;
		left: 45%;
	}
}

.container-negociador{

	
	border-top: 6px solid $C_SECONDARY;		
	position: relative;
	padding: 20px;
	margin-top: 70px;
	border-radius: 3px;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14),  0px 2px 1px -1px rgba(0,0,0,0.12);
	-webkit-box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.2), 0px 1px 1px 1px rgba(0,0,0,0.14),  0px 2px 1px -1px rgba(0,0,0,0.12);
	-moz-box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.2), 0px 1px 1px 1px rgba(0,0,0,0.14),  0px 2px 1px -1px rgba(0,0,0,0.12);


	.img-negociador{
		max-height: 75px;
		border-radius: 75px;
		position: absolute;
		left: calc(50% - 37px);
		top: -37px;
		box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.2), 0px 1px 1px 1px rgba(0,0,0,0.14),  0px 2px 1px -1px rgba(0,0,0,0.12);
		-webkit-box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.2), 0px 1px 1px 1px rgba(0,0,0,0.14),  0px 2px 1px -1px rgba(0,0,0,0.12);
		-moz-box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.2), 0px 1px 1px 1px rgba(0,0,0,0.14),  0px 2px 1px -1px rgba(0,0,0,0.12);

	}

	.negociador-talk{
		margin-top: 40px;
		text-align: center;		
		position: relative;


		h3{
			font-style: italic;
		}
		hr{
			width: 30%;
			border-color: #d4d4d4;
			height:3px; 
			border:none; 
			color: #d4d4d4; 
			background-color: #d4d4d4; 
			text-align: center;
			
			margin: 20px auto;
		}

		.paragraph-quotes{
			position: relative;
			
			
		}

		.question-quotes{
			font-weight: 700;
		}
		
		.explanation-quotes{
			position: relative;
		}
		
		blockquote{
			&:before{
				display: inline-block;
				padding-left: 10px;
				content: "\201C";
				font-size: 120px;				
				color: #eaeaea;
				position: absolute;
				top: -45px;
				left:0;
			}
		}

		p{
			font-size: 15px;
		}
		/*h3:before {			
			display: block;
			padding-left: 10px;
			content: "\201C";
			font-size: 80px;
			position: absolute;
			left: -30px;
			top: -30px;
			color: #f3f3f3;
		}*/

	}

	

}



.mercedes-benz-template{
	margin-top: 55px;	



	.btn1{
	  background-color: #E30613;
	  color: #fff;

	  &:hover{
	    background-color: #bb141e;
	  }

	}


	.main-container-info{
		width: 100%;
	}

	.back-image-main-container{		
		
		background-position: center; 
		background-repeat: no-repeat; 
		background-size: cover;
		min-height: 500px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0;
		
		
		@include tablet{
			min-height: 300px;			
			margin-top: 0;
		}

		@include phone{
			min-height: 200px;
			margin-top: 0;
		}
		
	}

	.incritos-container-mb{
			
		text-align: center;
		position: absolute;
		top: 30px;
		right: 100px;
		background-color: #fff;
		padding: 0 15px; 

		@include tablet{
			top: 5px;
			right: 20px;
		}

		@include phone{
			top: 5px;
			right: 5px;
		}

		
		.inscrito-main-container{
			color: #000;
			display: inline-block;
			text-align: center;
			position: relative;
			padding-bottom: 10px;
			.number-inscritos-container{
				font-size: 100px;		

				@include phone{
					font-size: 50px;
				}			
			}

			.inscrito-text-container{
				text-transform: uppercase;
				font-size: 14px;
				margin-top: -20px;
			}

		}
	}

	.container-top-info{		

		position: absolute;
		top: 80px;
		width: 100%;		
		text-align: center;

		@include desktop{
			top: 80px;
		}

		@include tablet{
			top: 45px;
		}	

		@include phone{
			top: 75px;
		}	

		.main-car-image{
			max-width: 100% !important;	
		}
	}

	.container-item{	
		background-color: #fff;	
		p{
			svg{
				color: #E30613;				
			}
		}
	}

	.mobile-container-title-custom-landing{		
		h2{			
			svg{
				color: #E30613;
				
			}
		}
	}

	.container-btn-interested-custom{		

	    .container-interested{	
	    	.name-interested{
	    		.event-date-final{	    			
	    			svg{
	    				color: #E30613;
	    			}
	    		}
	    		.event-location{	    			
	    			svg{
	    				color: #E30613;	    				
	    			}
	    		}
	    	}
	    }

	    .btn-interested-bar{	    	
	  		background-color: #E30613;		  			
	  		&:hover{
	  			background-color: #bb141e;
	  		}	  		
	    }
	}

	.container-call-me{
		
		border: 2px solid #404040;	
		background-color: #f1f1f1;	
			
		.container-inputs-call-me{			
			.input-label-privacity{
				font-size: 11px;
				margin-left: 5px;
				a{
					color: #E30613;
				}
			}
		}

		.label-call-me{
			color: #404040;
		}
	}

	.container-call-me-container{
		.container-inputs-call-me{			
			.input-label-privacity{
				font-size: 11px;
				margin-left: 5px;
				a{
					color: #E30613;
				}
			}
		}

		.label-call-me{
			span{
				color: #E30613;
			}
			color: #404040;
			a{
				color: #E30613;
			}
		}
	}

	.container-block-info-mp{
		position: absolute;
		top: 90px;
		left: 30%;

		img{
			max-height: 220px;
		}

		@include desktop{
			left: 10%;
		}

		@include tablet{
			left: 10%;
			top: 85px;
			img{
				max-height: 150px;
			}
		}

		@include phone{
			left: 10px;
			
			img{
				max-height: 80px;
			}
		}
	}

	.container-btn-advice{

		margin-top: 30px;

		@include tablet{
			margin-top: 30px;
		}

		@include phone{
			margin-top: 0;
		}
	}

	.container-top-button{		

		span{
			
			color: #E30613;			
			border: 2px solid #E30613;

			&:hover{
				background-color: #E30613;
				color: #fff;
			}

			

		}
	}


}

.mercedes-benz-template-dialog{

	div{
		div{
			div{
				background-color: #000;
			}
		}
	}


	.btn1{
		background-color: #000;
		color: #fff;

		&:hover{
			background-color: #bb141e;
		}
	}

	.form-more-info{
		div{
			background-color: #fff;
		}
		.input-label{
			a{
				color: #bb141e;
			}
		} 
	}

	.inputContainer{
  		input, select, textarea{
    		&:focus{
		      	box-shadow: 0 0 5px rgba(277, 6, 19, 0.5) !important;    
		      	border: 1px solid rgba(277, 6, 19, 1) !important;
    		}
  		}
	}


}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
}

@keyframes color-pulse
{
  0% {
    color: #3a3a3a;
  }
  50% {
    color: #E30613;
  }
  100%{
  	color: #3a3a3a;
  }
}


.btn-register-popup{
	background-color: transparent;
	border: 2px solid #fff;
	color: #fff;
	font-size: 16px;
	letter-spacing: 2px;
	padding: 5px 13px;
	border-radius: 25px;

	&:hover{
		background-color: #fff;
		color: #00009A;
	}
}

.certified-template{
	
	.btn1{
	  background-color: #225a9b;
	  color: #fff;

	  &:hover{
	    background-color: #1a4475;
	  }

	}
	
	.container-item{		
		p{
			svg{
				color: #225a9b;				
			}
		}
	}

	.mobile-container-title-custom-landing{		
		h2{			
			svg{
				color: #225a9b;
				
			}
		}
	}

	.container-btn-interested-custom{		

	    .container-interested{	
	    	.name-interested{
	    		.event-date-final{	    			
	    			svg{
	    				color: #225a9b;
	    			}
	    		}
	    		.event-location{	    			
	    			svg{
	    				color: #225a9b;	    				
	    			}
	    		}
	    	}
	    }

	    .btn-interested-bar{	    	
	  		background-color: #225a9b;		  			
	  		&:hover{
	  			background-color: #1a4475;
	  		}	  		
	    }
	}

	.container-call-me{
		
		border: 2px solid #225a9b;	
		background-color: #f1f1f1;	
			
		.container-inputs-call-me{			
			.input-label-privacity{
				
				a{
					color: #225a9b;
				}
			}
		}

		.label-call-me{
			color: #133358;
		}
	}

}

.imp-info{
	animation: shadow-pulse 1s infinite;
}

.mercedes-benz-template-dialog{

	
	.btn1{
		background-color: #000;
		color: #fff;

		&:hover{
			background-color: #bb141e;
		}
	}

	.form-more-info{
		div{
			background-color: #fff;
		}
		.input-label{
			a{
				color: #225a9b;
			}
		} 
	}

	.inputContainer{
  		input, select, textarea{
    		&:focus{
		      	box-shadow: 0 0 5px rgba(227, 3, 20, 0.5) !important;    
		      	border: 1px solid rgba(227, 3, 20, 0.5) !important;
    		}
  		}
	}


}


.future-event{
	margin-top: 125px;
}

.current-event{
	margin-top: 55px;
	.container-block-info-img-mp{
		position: absolute;
		top: 85px;
		left: 30%;
		height: 200px;
		width: 500px;
		z-index: 1;

		img{
			width: 100%;
		}

		@include desktop{
			left: 10%;
		}

		@include tablet{
			left: 10%;
			top: 55px;
			width: 400px;
		}

		@include phone{
			left: 10px;
			width: 275px;   
			top: 80px; 		
		}

	}
	.container-block-info-mp{
		position: absolute;
		top: 90px;
		left: 30%;
		height: 200px;
		width: 500px;
		background-color: #fff;
		 -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.3);
		-moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.3);
		box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.3);
		border-radius: 3px;

		.title-event{
			margin-left: 10px;
			margin-top: 40px;
			text-align: center;
			font-weight: 700;
		}

		.date-event{
			text-align: center;
			font-size: 25px;
    		margin-top: 10px;
		}
		/*
		img{
			max-height: 220px;
		}*/

		@include desktop{
			left: 10%;
		}

		@include tablet{
			left: 10%;
			top: 90px;
			height: 140px;
			.title-event{
				margin-top: 15px;
				font-size: 22px;
			}
			.date-event{
    			font-size: 16px;
    		}
			img{
				max-height: 150px;
			}
		}

		@include phone{
			left: 10px;
			top: 90px;
			width: 300px;
    		height: 110px;
    		.title-event{
    			font-size: 18px;
    		}
    		.date-event{
    			font-size: 16px;
    		}
			img{
				max-height: 80px;
			}
		}
	}

	.container-top-info{		

		position: absolute;
		top: 80px;
		width: 100%;		
		text-align: center;
		z-index: 10;
		@include desktop{
			top: 80px;
		}

		@include tablet{
			top: 30px;
		}	

		@include phone{
			top: 85px;
		}	

		.main-car-image{
			max-width: 100% !important;	
		}
	}

	.container-fluid-btn-interested{
		@include phone{
			margin: 60px auto;
		}	
		
	}
}


