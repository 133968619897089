
.template-container-footer{
	
}

.footer-container{

	background-color: #151515;
	padding: 30px 15px;
	z-index: 10000;

	.footer-content{
		
		color: #545454;

		a{
			color: #545454;

			&:hover{
				color: #737272;
			}
		}

		.logo-content{
			text-align: center;
			svg{
				font-size: 60px;
			}
		}

		

		.contact-content{
			margin: 0 10px 30px 10px;
			
			.title-footer{
				text-transform: uppercase;
				text-align: left;
	    		border-bottom: 1px solid #4b4b4b;
	    		margin-left: 20px;
	    		padding-bottom:5px;
	    		font-family: $brandFontSerif;
	    		@include phone{
	    			text-align: center;
	    			margin-left: 0;
	    		}
			}
			@include phone{
	    		text-align: center;
	    	}
			a{
				display: block;
				font-size: 14px;
				padding:  5px 20px;
			}
		}

		.footer-links-content{
			text-align: right;
			margin: 0 10px 30px 10px;
			a{
				display: block;
				font-size: 14px;
				padding:  5px 20px;
			}

			@include phone{
				text-align: center;
			}

			.title-footer{
				text-transform: uppercase;
				text-align: right;
	    		border-bottom: 1px solid #4b4b4b;
	    		margin-right: 20px;
	    		padding-bottom:5px;
	    		font-family: $brandFontSerif;
	    		@include phone{
	    			text-align: center;
	    			margin-right:0;
	    		}
			}
		}

		.separator{
			border-top: 1px solid #232323;
			width: 100%;
			height: 15px;
		}

		.copyright-content{
			font-size: 14px;

			@include tablet{
				text-align: center;
			}
		}

		.copyright-links-content{
			font-size: 14px;
			text-align: right;

			@include tablet{
				text-align: center;
			}
			
		}


		.politics-content{
			text-align: center;
			a{
				padding: 0 15px;
				font-size: 14px;

				&:hover{
					color: #737272;
				}
			}
		}
		.interests-link-content{
			text-align: left;
			@include tablet{
				text-align: center;
			}
		}

		.interest-privacy-content{
			text-align: center;
			margin-bottom: 20px;
			display: inline-block;
			a{
				padding: 0 5px;
				font-size: 14px;

				&:hover{
					color: #737272;
				}
			}
		}

		.interest-links-content{
			text-align: center;
			margin-bottom: 20px;
			display: inline-block;
			a{
				padding: 0 15px;
				font-size: 14px;

				&:hover{
					color: #737272;
				}
			}
		}


		.social-media-content{
			text-align: right;
			
			svg{
				
				font-size: 20px;
			    margin: 0 10px;
			    cursor: pointer;
			    
			    &:hover{
			    	color: #737272;
			    }

			    @include tablet{
					margin: 0 5px;
				}

				@include phone{
					margin: 20px 10px;
				}

			}

			@include tablet{
				text-align: center;
			}
		}
	}

}


.cookie-container{
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);


	.cookies-message{
		font-size: 10px;
		color: #b1b1b1;
		padding-left: 15px;		
		text-align: center;
		a{
			color: #b1b1b1;
			&:hover{
				color: $C_SECONDARY;
			}

		}
		svg{
			margin-left: 20px;
			cursor: pointer;
			font-size: 16px;
			&:hover{
				color: #545454;
			}
		}
	}

	

}







