.container-sharebutton{
	display: inline-block;
	margin-left: 15px;
	margin-right: 15px;

	svg{
		color: #fff;
	}
}

.container-btn-share{

	display: inline-block;
	text-align: center;
	cursor: pointer;
	color: #888;
	position: relative;
	margin: 20px auto;

	div{
		display: inline-block;

	}

	&:hover{
		color: $C_SECONDARY;
	}

	

	.share-in{
		font-size: 12px;
		background-color: #007bb5;
		padding: 5px 9px;
		border-radius: 17px;
		color: #fff;
		margin-right: -5px;
		
	}

	.share-twitter{
		font-size: 18px;
		background-color: #1da1f2;
		padding: 4px 9px;
		border-radius: 17px;
		color: #fff;
		margin-right: -7px;
	}

	.share-fb{
		font-size: 22px;
		background-color: #3b5998;
		padding: 5px 15px;
		border-radius: 25px;
		color: #fff;
		position: relative;
		z-index: 100;
	}

	.share-envelope{
		font-size: 18px;
		background-color: #a2a2a2;
		padding: 5px 9px;
		border-radius: 17px;
		color: #fff;
		margin-left: -7px;
		position: relative;
		z-index: 75;
	}

	.share-whatsapp{
		font-size: 14px;
		background-color: #25d366;
		padding: 5px 9px;
		border-radius: 17px;
		color: #fff;
		margin-left: -5px;
		position: relative;
		z-index: 70;
	}


	.text-share{
		display: block;
		font-size: 16px;
		font-family: $brandFont;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin: 5px;
	}
}

.container-share-btns{

	
	margin-bottom: 40px;

	@include phone{
		padding: 10px;
	}

	.header-share{
		text-align: right;
		margin-top: 20px;
		svg{
			font-size: 40px;
			cursor: pointer;
		}
	}

	p{
		text-align: center;
		font-size: 25px;

	}
}

.list-share-btn{
	
	text-align: center;
	font-size: 25px;
	color: #fff;
	svg{
		cursor:pointer;
		margin: 15px auto;
	}

	.share-btn-container{
		margin: 10px auto;
	}
}


.facebook-icon{

	background-color: #3b5998;
	/*padding: 21px 26px;
    border-radius: 35px; */
   
    border-radius: 33px;
    border: 2px solid #3b5998;
    max-width: 56px;
    max-height: 56px;
    margin: 0 auto;

    &:hover{
		color: #3b5998;
		background-color: #fff;
	}
	
}

.whatsapp-icon{

	background-color: #25d366;
	/*padding: 21px 23px;
	border-radius: 35px;*/
	border: 2px solid #25d366;	
	border-radius: 33px;
	max-width: 56px;
    max-height: 56px;
    margin: 0 auto;

	&:hover{
		color: #25d366;
		background-color: #fff;
	}	
}


.linkedin-icon{
	
	background-color: #007bb5;	
	
    border-radius: 31px; 
	border: 2px solid #007bb5;
	max-width: 56px;
    max-height: 56px;
    margin: 0 auto;

	&:hover{
		color: #007bb5;
		background-color: #fff;
	}
	
}



.twitter-icon{
	background-color: #1da1f2;	
    border-radius: 35px;
    border: 2px solid #1da1f2;	
    max-width: 56px;
    max-height: 56px;
    margin: 0 auto;
    cursor: pointer;

    &:hover{
		color: #1da1f2;
		background-color: #fff;
	}

    /*
	svg{		

    	&:hover{
    		color: #1da1f2;
    		background-color: #fff;
    	}

    	@include phone{    		
    		font-size: 20px;
    		padding: 12px;
    		border-radius: 24px; 
    	}
	}
	*/
}

.envelope-icon{

	background-color: #a2a2a2;
	/*padding: 21px;
	border-radius: 35px;*/
	border: 2px solid #a2a2a2;	
	
    border-radius: 33px;
    max-width: 56px;
    max-height: 56px;
    margin: 0 auto;

    &:hover{
		color: #a2a2a2;
		background-color: #fff;
	}

	
}

.container-copy-share{
	margin: 20px auto;
	text-align: center;

	.input-path{
		border: 1px solid #d4d4d4;
		font-size: 12px;
		padding: 7px 15px;
		border-radius: 5px;
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap; 
		overflow: hidden;
		width: 70%;
		color: #a5a5a5;
		&:focus{
			border-color: #777;			
  			box-shadow: none;
  			outline: 0 none;
		}		
	}

	.copy-to-friends{
		margin: 0;

		@include phone{
			font-size: 14px;
		}
	}

	.copy-icon{
		
		font-size: 12px;
		padding: 7px 0 7px 5px;
		border-radius: 5px;
		display: inline-block;		
	}

	svg{
		font-size: 20px;
		padding-left: 15px;
		color: $C_TEXT;
		&:hover{
			color: #929292;
			cursor: pointer;
		}
	}
}

.container-user-message{

	color: #f5f5f5;
	margin-bottom: 20px;	
	background-position: center; 
	background-repeat: no-repeat; 
	background-size: cover;
	padding: 30px 0;
	text-align: center;

	-webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	border-left: 5px solid red;

	@include tablet{
		width: 90%;
		margin: 0 auto 20px auto;
	}

	@include phone{
		border-left: 0;
		width: 100%;
		background-color: #f3f3f3 !important;

	}
	/*
	background-color: #05bdac;
	*/


	.user-message-name{
		font-size: 20px;	    
	    font-style: italic;
	    margin: 0;
	    color: #292929;
	    
	}

	.user-message-text{
		margin: 0;
    	font-size: 16px;
    	text-align: center;

    	@include phone{
    		width: 70%;
    		margin: 0 auto;
    	}
	}

	.btn-edit-profile{
		font-size: 12px;	    
		letter-spacing: 1px;
	    border-radius: 3px;
	    padding: 10px 18px;	    
	    outline: none;
	    border: 0;
	    text-transform: uppercase;	    
	    background-color: $C_SECONDARY;
	    color: #fff;
	    
	}

	.covid-text{
		font-size: 14px;
    	padding: 0 50px;
    	margin-bottom: 30px;
    	color: #292929;
    	@include tablet{
    		padding: 0 20px;
    	}

    	@include phone{
    		padding: 0 10px;
    	}
	}

}

.alert-text{
	text-align: center;
	margin: 0;

	@include phone{
		margin-top: 20px;
	}
}

.snackbar-text-msg{
	display: 'flex';
    align-items: 'center';

	svg{
		margin-right: 10px;
		font-size: 22px;
		
	}

}


.faqs-block-container{

	
	padding: 120px 0;
	background-color: #f2ffff;

	.block-grid-container{
		padding: 10px 10px 0 10px;					
	}

	.title-faqs{
		text-align: center;
		font-size: 32px;
		font-weight: 700;
		margin: 0 0 50px 0;
		
		@include tablet{
			font-size: 30px;
		}

		@include phone{
			font-size: 22px;
		}

		/*
		&:before, &:after{
			display: inline-block;
		    content: "";
		    border-bottom: 1px solid black;
		    width: 100px;		   
		    margin: 0 1rem;
		    transform: translateY(-1rem);

		    @include tablet{
		    	width: 75px;
		    }

		    @include phone{
				display: none;
			}
		}
		*/
	}

	.container-faq{
		padding: 10px;
		height: 100%;
		margin: 5px;
	}

	.faqs-question{

		
		
		font-weight: 700;		
		font-size: 14px;
		letter-spacing: 1px;
		padding-bottom: 5px;
		margin: 0;

		@include phone{
			padding-right: 0 !important;
		}


		/*&:first-letter{
			font-size: 200%;
			color: $C_SECONDARY_DARK;
		}*/
		svg{
			margin-right: 15px;
			font-size: 22px;
			color: $C_SECONDARY;
		}
	}

	.faqs-answer{

		font-size: 14px;
		text-align: justify;
		margin: 0 0 30px 0;
		a{
			color: $C_SECONDARY;
			text-decoration: underline;
		}

	}


}

.logged{
	.container-call-me-container{
		position: fixed;
		top: 70px;
		right: 50px;
		z-index: 1000;
		border-radius: 2px;
	}
}

.public{
	.container-call-me-container{
		position: fixed;
		top: 0px;
		right: 50px;
		z-index: 1000;
	}
}


.container-call-me-container{
	
	background-color: #fff;	
	display: inline-block;
	padding: 15px;
	
	-webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);

	@include tablet{
		display: none;
	}

	.inputContainerCheckbox{
		display: inline-block;
		input{
			display: inline-block;
		}
		
	}
	
	.container-inputs-call-me{
		
		display: inline-block;
		margin-top: 15px;
		input{
			background-color: #fff;		
	    	color: #505050;
	    	font-size: 16px;
	    	font-weight: normal;
	    	border: 1px solid #A2A2A2; 
	    	/*border: 1px solid #fff;*/
	    	
	    	&:focus{
	    		border: 1px solid #e30314 !important;
	    		box-shadow: 0 0 5px rgba(227, 3, 20, 0.5) !important;
	    	}
		    	
		}

		.input-text-call-me{
			width: 90%;
		}

		.input-label-privacity{
			font-size: 11px;
			a{
				color: $C_SECONDARY;
			}
		}

	}

	

	.label-call-me{
		color: #3a3a3a;
		font-size: 17px;
		margin: 20px 5px 5px 5px;
		text-align: center;

		span{
			font-size: 25px;
			color: $C_SECONDARY;
		}

		a{
			font-size: 25px;
			color: $C_SECONDARY;
		}
		
	}



	.btn-call-me{
		font-size: 12px;	    
		letter-spacing: 1px;
	    border-radius: 3px;
	    padding: 15px 8px;	    
	    outline: none;
	    border: 0;
	    text-transform: uppercase;
	    margin: 25px 0 10px 0;
	    width: 100%;
	    display: block;
	}

	.icon-call-me{
		margin-right: 5px;
		
	}

	.icon-right{
		margin: 5px;
		font-size: 25px;
	}


	.error-container{
		color: $ERROR_COLOR;
		text-align: left;
		font-size: 12px;
		margin-bottom: 10px;
		margin-top: 2px;
		.icon-error{
			margin-right: 5px;
		}
	}
}

.container-call-me{
	
	
	
	border: 2px solid #fb900c;	
	background-color: #fff5e9;	
	display: inline-block;
	padding: 15px;
	border-radius: 2px;
	position: relative;

	.inputContainerCheckbox{
		display: inline-block;
		input{
			display: inline-block;
		}
		
	}
	
	.container-inputs-call-me{
		
		display: inline-block;

		input{
			background-color: #fff;		
	    	color: #505050;
	    	font-size: 16px;
	    	font-weight: normal;
	    	border: 1px solid #A2A2A2; 
	    	/*border: 1px solid #fff;*/
	    	

	    	
		}

		.input-text-call-me{
			width: 90%;
		}

		.input-label-privacity{
			font-size: 11px;
			a{
				color: $C_SECONDARY;
			}
		}

	}

	

	.label-call-me{
		color: #fb900c;
		font-size: 18px;
		margin: 5px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 1px;
		text-align: center;

		
	}



	.btn-call-me{
		font-size: 12px;	    
		letter-spacing: 1px;
	    border-radius: 3px;
	    padding: 15px 8px;	    
	    outline: none;
	    border: 0;
	    text-transform: uppercase;
	    margin: 10px 0;
	    width: 100%;
	    display: block;
	}

	.icon-call-me{
		margin-right: 5px;
		
	}

	.icon-right{
		margin: 5px;
		font-size: 25px;
	}


	.error-container{
		color: $ERROR_COLOR;
		text-align: left;
		font-size: 12px;
		margin-bottom: 10px;
		margin-top: 2px;
		.icon-error{
			margin-right: 5px;
		}
	}

	
}


.container-wrapper-ventas{
	background-color: $C_SECONDARY;
	padding: 50px 15px;

	.block-container-seller{
		padding: 15px;

	}

	

	.container-wrapper-all{

		overflow: hidden;
		width: 100%;
		background-color: #000;
		position: relative;
		border-radius: 3px;

		&:hover {		   
		    .more-container{
		    	opacity: 1;
		    }
		    .container-img-seller{
		    	opacity: 0.5;
		    	transform: scale(1.03);
			    transition: 0.9s all ease;
		    }
		}

		.more-container{
			position: absolute;
			top: calc( 50% - 10px );
			left: calc( 50% - 35px );
			opacity: 0;
			color: #fff;
			font-size: 12px;
			border: 1px solid #fff;
			padding: 5px 10px;
			letter-spacing: 1px;
			text-transform: uppercase;
			transition: 0.3s all ease;
		}

		.container-img-seller{
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			width: 100%;
			padding-bottom: 65%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
			opacity: 1;
			
		}
	}

	

	.container-txt-seller{		
		height: 50px;
		padding: 15px;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		h3{
			margin: 0;			
			color: #fff;
			text-align: center;
			font-size: 14px;
		}
	}
}
